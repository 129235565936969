/* ==========================================================================
   
   ANIMATE

========================================================================== */

// Hero BG Fade/move
@keyframes heroBg {

	100% {

		transform: translateX(0);
		opacity: 1;

	}

}

// Hero Home Fade In
@keyframes heroHomeFadeIn {

	100% {

		opacity: 1;
		transform: translateY(0);
		
	}

}

@keyframes numberedLine {

	100% {

		height: 200%;

	}

}

@keyframes bgScale {

	100% {

		transform: scale(1);

	}
	
}

/* AOS
========================================================================== */
[data-aos="e3-zoom-in"] {

  transform: translateY(100px) scale(1.1);
  opacity: 0;
  transition: all 0.6s ease-in-out;

  &.aos-animate {

    transform: translateY(0) scale(1);
    opacity: 1;

  }

}