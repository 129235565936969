/* Error 404 page specific styling */
.error404 {

    .hero-content {
        height:600px;

        .desc {
            line-height:1.4em;
        }

        .form-submit {

            position: relative;
            margin-bottom: 28px;

            @include media-breakpoint-up(md) {

                max-width: 80%!important;
                margin-left: 0;
                margin-right: auto;

            }

            @include media-breakpoint-up(lg) {
                max-width: 100%;
            }

            .search-field {

                width: calc(100% - 30px);
                height: 50px;
                color: $black;
                padding-right: 108px;
                padding-left: 15px;

                @include media-breakpoint-up(sm) {

                    height: 78px;
                    font-size: 18px;
                    padding-right: 180px;

                }

            }

            .submit {

                font-size: 10px;
                height: 50px;
                padding: 0 15px;

                position: absolute;
                top: 0;
                right:15px;

                @include media-breakpoint-up(sm) {

                    padding: 0 40px;
                    height: 78px;
                    font-size: 14px;

                }

                @include media-breakpoint-up(md) {
                    right:0;
                }

            }

        }
    }

    .error-page-message {

        @extend .p-4;

    }
}