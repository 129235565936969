.anchor-nav {
	position: relative;
	
	.anchor-nav-burger {
    	position: absolute;
    	top: 0;
    	left: 0;		
		background-color: #e01735;
		cursor:pointer;
		z-index:2;
		height: 50px;
    	width: 50px;		
    	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45);

		@include media-breakpoint-up(md) {
			height:70px;
			width:70px;
		}

		@include media-breakpoint-up(xl) {
		    height: 90px;
		    width: 90px;
		}

		&.fixed {
			position: fixed;
			z-index:10;
			top: 85px;

			@include media-breakpoint-up(lg) {
				top:114px;
			}
		}

		.inner-image {
			height: 100%;
    		width: 100%;
    		position: relative;
    		margin-left: auto;
    		margin-right: auto;			
    		max-width: 75%;

    		@include media-breakpoint-up(md) {
    			max-width: 50%;
    		}

			span {
				position: absolute;
				background-color:$light;
				width:50%;
				top:50%;
				transform:translateX(50%);
				height:2px;
				left:0;
				right:0;
				transition: 0.5s;
				transform-origin:center;

				&:first-of-type {
					transform: translate(50%, -6.5px);
				}

				&:last-of-type {
					transform: translate(50%, 6.5px);
				}
			}
		}
	}

	.anchor-nav-menu {
		background-color:#292929;
		position: absolute;
		z-index: 10;
		transform: translateX(-100%);
		transition: 0.5s;	
		width: 100%;

		@include media-breakpoint-up(sm) {
			width:auto;
		}

		&.scroll {
			overflow: scroll;	
		}

		&::-webkit-scrollbar { 
		    display: none; 
		}					

		.back {
    		position: absolute;
    		right: 0;			
    		text-align: center;
    		cursor:pointer;
    		height: 70px;
    		width: 70px;

    		@include media-breakpoint-up(lg) {
	    		height: 90px;
	    		width: 90px;
    		}

			span {
				color:$light;
				line-height: 70px;

				@include media-breakpoint-up(lg) {
					line-height: 90px;
				}
			}
		}

		&.fixed {
			top: 85px;
			position:fixed;

			@include media-breakpoint-up(lg) {
				top:114px;	
			}
		}

		.menu-list {
    		padding-left: 0;
    		list-style-type: none;	
    		margin-bottom: 0;
    		padding-top: 60px;
    		padding-bottom: 40px;    		

    		@include media-breakpoint-up(md) {
	    		padding-top: 90px;
	    		padding-bottom: 60px;    				
    		}

			&__item {
				a {
					color:$white;
					text-transform: uppercase;
					display: block;		
					letter-spacing:2px;		
					transition:0.25s;
					padding: 10px 40px 10px 30px;
    				font-size: 13px;
    				text-align:center;					

					@include media-breakpoint-up(sm) {
						text-align:left;
						font-size: 14px;
						padding: 12px 60px 12px 50px;
					}

					@include media-breakpoint-up(md) {
						font-size:15px;
						padding: 15px 90px 15px 60px;	
					}

					&:hover {
						background-color:lighten(#292929, 2%);
					}
				}
			}
		}
		.ctas {
			&__btn {
				transform:translateX(0);
				width: 100%;
				background-color:#e01735;
				max-width: none;

				span {
					transform:translateX(0);					
				}
			}
		}
	}

	&.active {
		.anchor-nav-menu {
			transform: translateX(0%);
		}
	}
}
