/* ==========================================================================

   HERO

========================================================================== */

$width-large: 1600px;

.hero {

  position: relative;
  transition: all 0.4s ease-in-out;

  .divider-line {
    height: 2px;
    width: 100%;
    max-width: 50px;
    margin-bottom: 10px;
  }

  &.playing-video {

    @include media-breakpoint-up(xl) {

      margin-bottom: 80px;

    }

    .hero-content {

      .title,
      .sub-title {

        opacity: 0;
        visibility: hidden;
        transition: all 0.1s linear;

      }

      &:before {

        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);

      }

    }

  }

  .go-back {

    font-size: 35px;
    font-family: $font-secondary;
    color: $black;
    display: block;
    transform: translateX(0);
    transition: transform 0.3s ease-in-out;

    @include media-breakpoint-up(md) {

      &:hover {

        transform: translateX(-15px);

      }

    }

    .icon {

      font-size: 24px;
      margin-right: 20px;

    }

  }

  &.hero-featured-sticky {

    .hero-inner {

      @include media-breakpoint-up(md) {

        display: flex;
        align-items: center;

      }

      .hero-content {

        @include media-breakpoint-up(md) {

          width: 50%;
          flex-grow: 1;

          display: flex;
          align-items: center;

        }

      }

      .featured-sticky {

        width: 50%;

        opacity: 0;
        transform: translateY(20px);
        animation: heroHomeFadeIn 0.6s cubic-bezier(.02, .5, .99, 1) forwards;

        @include media-breakpoint-up(md) {

          margin-left: auto;

        }

        @media (min-width: 1400px) {

          width: 65%;
          max-width: 800px;

        }

        .post-box-small {

          @include media-breakpoint-up(lg) {

            margin-bottom: 0;

          }

          @media (min-width: 1400px) {

            display: flex;
            width: 800px;

          }

          .title {

            font-size: 35px;
            line-height: 40px;
            text-transform: initial;
            max-width: 100%;

            opacity: 1;
            transform: translateY(0);

            @include media-breakpoint-up(md) {
              max-width: 60%;
            }

          }

          .post-box-image {

            height: auto;
            display: none;

            @media (min-width: 1400px) {

              height: 400px;
              width: 50%;
              order: 2;
              display: block;

            }

            @include media-breakpoint-up(xxl) {

              height: 380px;

            }

          }

        }

      }

    }

  }

  &:before {

    content: '';
    @include z-index(heroGradient);

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: none !important;
  }

  .hero-bg {

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .3;

    transition: all 0.2s ease-in-out;

    &.hidden {

      opacity: 0;
      visibility: hidden;

    }

    @include media-breakpoint-up(xl) {
      opacity:1;
    }

    > .bg {

      position: absolute;
      right: 0;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 100%;

      &.mobile {

        @include media-breakpoint-down(mobile-lg) {
          background-position: center;
        }

        @include media-breakpoint-up(xl) {

          display: none;

        }
      }

      &.desktop {

        @include media-breakpoint-down(lg) {

          display: none;

        }

      }

    }

  }

  .hero-content {

    .theme-icon {
      position: relative;
      height: 40px;
      width: 60px;
      font-size: 40px;
      color: $black;
      margin-bottom: 20px;
      display: block;

      &.theme-light {
        @include media-breakpoint-up(lg) {
          color: #fff;
        }
      }

      &:after {
        content: '';
        position: absolute;
        bottom: -10px;
        width: 100%;
        height: 2px;
        left: 0;
        background-color: $brand;
      }
    }

    position: relative;
    @include z-index(heroInner);
    transition: all 0.4s ease-in-out;

    &.hidden {

      opacity: 0;
      visibility: hidden;

    }

  }

  .hero-content {

    form {

      position: relative;
      max-width: 600px;
      width: 100%;
      margin-top: 30px;

      overflow: hidden;
      opacity: 0;
      transform: translateY(15px);
      animation: heroHomeFadeIn 0.8s ease-in-out forwards;
      animation-delay: 0.4s;

      @include media-breakpoint-up(md) {

        overflow: visible;

      }

      input {

        position: relative;
        height: 70px;
        width: 100%;
        padding-right: 180px;

        @include media-breakpoint-up(md) {

          width: 600px;

        }

        &:focus {

          box-shadow: none;
          border-color: #dfdfdf;

        }

      }

      .submit {

        position: absolute;
        top: 18px;
        right: -20px;
        height: 70px;

        @include media-breakpoint-up(md) {

          top: 6px;

        }

      }

    }

  }

  .title,
  .persona-select {
    transform: translateY(50px);
    transition: 0.75s;
    opacity: 0;
  }

  .persona-select {
    transition-delay: 0.25s;
  }

  &.aos-animate {
    .title,
    .persona-select {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  // Hero Home
  &.hero--home {

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 85vh;

    .hero-inner {
      display: flex;
      align-items: center;
      height: 100%;
    }

    .title {

      font-size: 30px;
      letter-spacing: 0.05em;
      line-height: 41px;
      color: $black;
      text-transform: uppercase;
      margin-bottom: 0;
      max-width: 400px;

      @include media-breakpoint-up(md) {
        font-size: 50px;
        line-height: 1.3;
        max-width: 80%;
      }

      @include media-breakpoint-up(lg) {
        max-width: 60%;
      }
      @include media-breakpoint-up(xxl) {

        font-size: 60px;
        line-height: 1.6;

      }

      span {

        color: $brand;
        font-family: $font-secondary;
        font-size: 35px;
        text-transform: capitalize;

        @include media-breakpoint-up(md) {

          font-size: 55px;

        }

        @include media-breakpoint-up(xxl) {

          font-size: 95px;
        }
      }
    }

    .para {
      @include media-breakpoint-up(md) {
        max-width: 50%;
      }
      @include media-breakpoint-up(lg) {
        max-width: 30%;
      }
    }

  }

  // Hero Page
  &.hero--page {
    overflow: hidden;

    @include media-breakpoint-up(md) {
      height: auto;
    }

    @include media-breakpoint-up(xl) {
      background-color: black;
    }

    &:after {
      content: '';
      position: absolute;
      height: 2px;
      transform: rotate(90deg);

      @include media-breakpoint-down(sm) {
        align-self: center;
      }
    }

    &.hero--page-sm .hero-content {

      @include media-breakpoint-up(lg) {

        padding-top: 190px;
        padding-bottom: 160px;

      }
    }

    &.hero--page-xs .hero-content {

      @include media-breakpoint-up(lg) {

        padding-top: 30px;
        padding-bottom: 30px;

      }

    }

    &.hero--page-lg .hero-content {
      height: 80vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .hero-bg {

      @include media-breakpoint-up(xl) {
        opacity: 0.5;
        &.remove-opacity {
          opacity: 1;
        }
      }

    }

    .hero-bg > .bg {
      background-position: right center;
      //top: 75px;

      @include media-breakpoint-up(md) {
        top: 0;
      }
    }

    .hero-content {
      padding-top: 40px;
      padding-bottom: 75px;

      @include media-breakpoint-up(md) {
        transform: translateY(0px);
        padding-top: 160px;
        padding-bottom: 190px;

      }

      @include media-breakpoint-up(lg) {

        padding-top: 190px;
        padding-bottom: 280px;

      }
    }

    + .container-fluid > .block-half-diag {

      @include media-breakpoint-up(xl) {

        margin-top: -80px;

      }

    }

    .title {

      font-size: 32px;
      line-height: 41px;
      text-transform: uppercase;

      opacity: 0;
      transform: translateY(15px);
      animation: heroHomeFadeIn 0.8s ease-in-out forwards;
      animation-delay: 0.2s;
      color:$black;

      @include media-breakpoint-up(md) {
        font-size: 40px;
        line-height: 1.3em;
        max-width: 60%;

      }

      @include media-breakpoint-up(lg) {
        font-size: 60px;
        color: $light;
      }

      @include media-breakpoint-up(xxl) {
        font-size: 70px;
        max-width:50%;
      }

      &.title-sm {

        @include media-breakpoint-up(md) {

          font-size: 45px;
          line-height: 61px;

        }
      }

      &.title-dark {
        color: $black;
      }

    }

    .sub-title {

      color: $brand;
      font-size: 20px;
      font-family: $font-secondary;
      line-height: 22px;
      margin-bottom: 15px;

      opacity: 0;
      transform: translateY(15px);
      animation: heroHomeFadeIn 0.8s ease-in-out forwards;

      @include media-breakpoint-up(md) {

        font-size: 35px;
        line-height: 40px;
        margin-bottom: 10px;

      }

    }

    .desc {

      line-height: 1.8;
      max-width: 400px;
      opacity: 0;
      transform: translateY(15px);
      animation: heroHomeFadeIn 0.8s ease-in-out forwards;
      animation-delay: 0.4s;
      font-size: 12px;
      color:$black;

      @include media-breakpoint-up(md) {
        font-size: 17px;
      }

      &.desc-dark {

        color: $black;

      }

      @include media-breakpoint-up(lg) {
        color: $light;
        max-width: 450px;

      }

      @include media-breakpoint-up(xxl) {
        max-width: 500px;
      }
    }

    &.hero-vertical-line {
      &:after {
        content: '';
        position: absolute;
        bottom: -50px;
        left: 5vw;
        z-index: 100;
        background: $brand;
        width: 2px;
        height: 100px;
        transform: rotate(0);
      }
    }
  }

  // Single Post
  &.hero--post {

    height: 260px;
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {

      height: 400px;

    }

    @include media-breakpoint-up(xl) {

      margin-bottom: 50px;

    }

    &:before {

      display: none;

    }

    .hero-content {

      padding-top: 60px;
      padding-bottom: 0;

    }

    .hero-bg > .bg {

      width: calc(100% + 30px);

    }

  }

  &.hero--video {

    overflow: hidden;
    transition: margin 0.3s ease-in-out;
    background-size: cover;

    &--black {
      background-color: $black;

      & * {
        color: white !important; // Override colours required for other pages
      }

      &::before {
        background-color: black !important; // Override pseudo element for other classes
        opacity: .6;
      }
    }

  }

  // Play
  .hero-video-play {

    position: relative;
    top: 50px;
    display: inline-block;
    text-align: center;
    width: 100%;

    @include media-breakpoint-up(lg) {

      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20vw;
      width: auto;

    }

    &:hover {

      a:before {

        transform: translate(-50%, -50%) scale(1.3);

      }

      .icon {

        transform: scale(0.7);

      }

    }

    a {

      display: block;

      &:before {

        content: '\e909';
        @include icons;
        font-size: 100px;
        color: $black;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        transition: transform .4s cubic-bezier(0.42, 0, 0, 1);

        @include media-breakpoint-up(lg) {
          color:$white;
        }

        @include media-breakpoint-down(md) {

          display: none;

        }

      }

      &.title-dark {
        &:before {
          color: $black;
        }
        .icon {
          color: $black;
          @include media-breakpoint-down(md) {
            color: $white;
          }
        }
      }

    }

    .icon {

      font-size: 100px;
      color: rgba(0, 0, 0, 0.1);
      display: block;
      transform: scale(1);
      transition: transform .4s cubic-bezier(0.42, 0, 0, 1);
      margin-bottom: 50px;

      @include media-breakpoint-up(lg) {

        font-size: 360px;
        color: $white;
        opacity: 0.1;
        margin-bottom: 0;

      }

    }

  }
  .hero-video-text {
    color: white;
    font-size: 24px;
    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      right: 16vw;
      width: auto;
    }
    @include media-breakpoint-down(md) {
      margin-top: 20px;
      width: 100%;
      text-align: center;
    }
  }
  // Play
  .insight-video-play {

    position: relative;
    top: 50px;
    display: inline-block;
    text-align: center;
    width: 100%;

    @include media-breakpoint-up(lg) {

      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 35vw;
      width: auto;

    }

    &:hover {

      a:before {

        transform: translate(-50%, -50%) scale(1.3);

      }

      .icon {

        transform: scale(0.7);

      }

    }

    a {

      display: block;

      &:before {

        content: '\e909';
        @include icons;
        font-size: 100px;
        color: $black;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        transition: transform .4s cubic-bezier(0.42, 0, 0, 1);

        @include media-breakpoint-up(lg) {
          color:$white;
        }

        @include media-breakpoint-down(md) {

          display: none;

        }

      }

      &.title-dark {
        &:before {
          color: $black;
        }
        .icon {
          color: $black;
        }
      }

    }

    .icon {

      font-size: 100px;
      color: rgba(0, 0, 0, 0.1);
      display: block;
      transform: scale(1);
      transition: transform .4s cubic-bezier(0.42, 0, 0, 1);
      margin-bottom: 50px;

      @include media-breakpoint-up(lg) {

        font-size: 360px;
        color: $white;
        opacity: 0.1;
        margin-bottom: 0;

      }

    }

  }

  .hero-video-container {

    position: absolute;
    top: 50%;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    transform: translateY(-50%);
    background-color: #000;
    display: none;

    @media (min-width: 400px) {

      top: calc(50% - 25px);
      width: 70%;
      margin-left: auto;
      margin-right: auto;

    }

    @include media-breakpoint-up(lg) {

      top: 0;
      height: 100%;
      width: 100%;
      transform: translateY(0);

    }

    &--dpm {
      height: 100%;
      width: 100%;

      @media (min-width: 400px) {
        top: 50%;
      }

      @include media-breakpoint-up(lg) {
        top: 0;
      }
    }
  }
  .insight-video-container {

    position: absolute;
    top: 50%;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    transform: translateY(-50%);
    background-color: #000;
    display: none;

    @media (min-width: 400px) {

      top: calc(50% - 25px);
      width: 70%;
      margin-left: auto;
      margin-right: auto;

    }

    @include media-breakpoint-up(lg) {

      top: 0;
      height: 100%;
      width: 100%;
      transform: translateY(0);

    }

  }

  &__posh-title {
    font-family: $font-secondary;
    color: $brand;
    display: inline-block;
    margin-right: 30px;
    font-size: 40px;
    text-transform: none;

    @include media-breakpoint-up(md) {
      font-size: 60px;
    }
  }

  &__paragraph {
    margin: 0;

    @include media-breakpoint-up(md) {
      width: 60%;
    }

    @include media-breakpoint-up(lg) {
      width: 40%;
    }

    &__container {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 6rem 3rem;
    }
  }
}

.hero-spacing {

  display: block;
  height: 30px;

  @include media-breakpoint-up(lg) {

    height: 50px;

  }

}

.persona-select {

  text-align: left;
  margin-top: 0px;

  &.below-hero {

    margin-top: 25px;
    margin-bottom: 40px;

    @include media-breakpoint-up(md) {

      display: none;

    }

  }

  p {
    line-height: normal !important;
    font-family: $font-secondary;
    color: $brand;
    display: inline-block;
    margin-right: 30px;
    font-size: 40px;

    @include media-breakpoint-up(md) {
      font-size: 60px;
    }

  }

}

.select2-container--chase {

  background: rgba($brand, 0.03);
  min-width: 250px;
  width: calc(100% - 10vw);
  min-height: 57px;

  display: inline-block;
  position: relative;
  text-align: left;

  @include media-breakpoint-up(md) {

    width: 100%;
    max-width: 350px;

  }

  @include media-breakpoint-up(xl) {

    min-height: 92px;
    max-width: 510px;

  }

  // Selected Text
  .select2-selection--single {

    font-size: 14px;
    color: $black;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    line-height: 57px;
    padding-left: 28px;

    @include media-breakpoint-up(xl) {

      font-size: 20px;
      line-height: 92px;
      padding-left: 40px;

    }

    .select2-selection__rendered {

      padding-left: 0;

    }

  }

  .select2-selection,
  .select2-selection__rendered {

    &:focus {

      outline: none;

    }

  }

  // Arrow
  .select2-selection__arrow {

    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);

    @include media-breakpoint-up(xl) {

      right: 40px;

    }

    &:before {

      @include icons;
      content: '\e901';
      font-size: 14px;
      color: $brand;

      @include media-breakpoint-up(xl) {

        font-size: 17px;

      }

    }

  }

  // Dropdown
  .select2-dropdown {

    background: rgb(248, 250, 253);
    padding-top: 20px;
    padding-bottom: 30px;

  }

  .select2-dropdown {

    border: none;

  }

  .select2-results__options {

    height: 220px;
    overflow-y: scroll;
    position: relative;
    padding-right: 30px;

  }

  .select2-results__option {

    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    color: $black;

    padding-left: 30px;
    margin-bottom: 15px;

    @include media-breakpoint-up(xl) {

      font-size: 20px;
      padding-left: 55px;
      margin-bottom: 35px;

    }

    &:last-child {

      margin-bottom: 0;

    }

  }

}

.embed-container {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  height: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.horizontal-line {
  border: 1px solid red;
  width: 40px;
  margin-bottom: 10px;
}
