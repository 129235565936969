.textarea-banner {
	@include background-cover();
	position: relative;

	.background-image {
		@include background-inner();
	}

	.textarea-banner-inner {
    	text-align: center;
    	max-width: 820px;
    	margin-left: auto;
    	margin-right: auto;
    	padding-left: 35px;
    	padding-right: 35px;
    	padding-top: 60px;
    	padding-bottom: 60px;

    	@include media-breakpoint-up(md) {
		    padding-top:80px;
		    padding-bottom:80px;			
    	}	

		@include media-breakpoint-up(xxl) {
	    	padding-top: 125px;
	    	padding-bottom: 125px;	
		}

		&__hdr {
			text-transform: uppercase;
			letter-spacing: 5px;
			margin-bottom: 30px;
			font-size:26px;

			@include media-breakpoint-up(xs) {
				font-size:30px;
			}

			@include media-breakpoint-up(md) {
				font-size:45px;
			}
		}

		&__para {
			line-height: 1.5;
			font-size:20px;
			margin-bottom:0;

			@include media-breakpoint-up(sm) {
				font-size:22px;
			}

			@include media-breakpoint-up(md) {
				font-size:25px;
			}

			+ .textarea-banner-inner__btn {
				margin-top: 45px;
			}
		}
	}

	&.with-image {
		background-color:$dark;

		.textarea-banner-inner {
			position: relative;
			z-index:1;

			&__hdr {
				color:white;
			}

			&__para {
				font-size:17px;
				color:white;
				line-height: 2;
			}
		}
	}
}