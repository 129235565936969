/* ==========================================================================
   
   EVENT SEARCH

========================================================================== */

.gmw-results-wrapper.custom {

	width: 100%;
	
}

div.gmw-form-wrapper.default {

	.gmw-form-field-wrapper {

		label {

			display: none;
			
		}

		input[type="text"] {

		    font-size: 15px;
		    padding-left: 30px;
		    padding-right: 125px;

		    @include media-breakpoint-up(sm) {

		    	padding-right: 150px;

		    }

		    @include media-breakpoint-up(xl) {

		    	padding-right: 180px;

		    }

		}

		i.gmw-locator-button {

			right: 125px;

			@include media-breakpoint-up(sm) {

				right: 150px;

			}

			@include media-breakpoint-up(xl) {

				right: 180px;
				
			}

		}

	}

	.gmw-submit-field-wrapper {

		display: none;

	}

}