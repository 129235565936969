/* ==========================================================================
   
   SERVICES

========================================================================== */

/* SERVICES HOMEPAGE
   ========================================================================== */

.page-template-cdv-services-index .hero {

	margin-top: 50px;

	@include media-breakpoint-up(xl) {

		margin-top: 0;

	}

}

/* SERVICES - INNER
   ========================================================================== */

.page-template-cdv-services-inner-child .services-container {

	margin-top: 0px;

}

.page-template-cdv-services-inner-child {

	.page-block-events + .page-block.light-bg {

		@include media-breakpoint-down(sm) {

			margin-top: -38px;
			
		}

	}

}

// Page Setup
.services-container {

	display: flex;
	flex-direction: column;

	@include media-breakpoint-up(xl) {

		flex-direction: row;

	}

	& + .page-block {

		margin-top: -40px;

		@include media-breakpoint-up(xl) {

			margin-top: -80px;

		}

	}

	.services-sidebar {

		position: relative;
		z-index: 500;
		background: $white-alt;

		@include media-breakpoint-down(lg) {

			position: fixed;
			top: 81px;
			left: 0;
			right: 0;
			width: 100%;
		
		}

		@include media-breakpoint-down(md) {

			top: 51px;

		}

		@include media-breakpoint-up(xl) {

			min-width: 280px;

		}

		@include media-breakpoint-up(xxl) {

			min-width: 328px;
			
		}

	}

	.services-main {

		width: 100%;

	}

}

// Sidebar
.services-sidebar {

	.service-list-mobile {
		
		position: relative;
		padding: 15px 20px;

		background: $brand;
		color: $white;
		font-size: 14px;
		font-weight: 600;
		text-transform: uppercase;
		text-align: center;
		letter-spacing: 0.2em;
		display: block;

		@include media-breakpoint-up(xl) {

			display: none!important;

		}

		&.active {

			.icon:before {

				transform: rotate(90deg);

			}
		}

		.icon {

		    font-size: 12px;
    		margin-left: 10px;

			&:before {

				color: $white;
				transition: all 0.3s ease-in-out;

			}

		}

	}

	.services-list {

		@extend .list-unstyled;
		display: none;

		@include media-breakpoint-up(xl) {

			display: block!important;

		}

		.list-item a {

			position: relative;
			padding: 25px 10px;

			color: $brand;
			font-size: 14px;
			font-weight: 600;
			text-transform: uppercase;
			text-align: center;
			letter-spacing: 0.2em;
			display: block;
			-webkit-backface-visibility: hidden;
			overflow: hidden;

			@include media-breakpoint-up(xl) {

				padding: 35px 10px;

			}


			&:not(.current) {

				&:hover {

					@include media-breakpoint-up(lg) {

						&:before {

							transform: translate3d(0, 0, 0);
							
						}

						> span {

							transform: translateX(50px);
							opacity: 0;

						}
						
					}

				}

				&:before {

					content: attr(data-text);
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					padding: 25px 10px;
					transform: translate3d(-100%, 0, 0);
					transition: transform 0.4s cubic-bezier(0.75, 0, 0.125, 1);
					background: $brand;
					color: $white;
					font-weight: 600;

					@include media-breakpoint-up(xl) {

						padding: 35px 10px;
						
					}

				}
				
			}

			> span {

				display: block;
				transform: translateX(0);
				transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out;
				opacity: 1;

			}

			&.current {

				color: $white;
				background: $brand;

			}

		}

		&.fixed{
			@include media-breakpoint-up(xl) {
				position: fixed;
				left: 0;
				width: 280px;
				bottom: auto;
				top:82px;
			}

			@include media-breakpoint-up(xxl) {

				min-width: 328px;
				
			}
		}
		&.bottom{
			@include media-breakpoint-up(lg) {
				position: absolute;
				top:auto;
				left: 0;
				width: 280px;
				bottom: 0;
			}
			@include media-breakpoint-up(xxl) {

				min-width: 328px;
				
			}

		}
		
	}

}

// Main
.services-main {

	&.main-index {

		margin-bottom: 50px;

		@include media-breakpoint-up(lg) {

			margin-bottom: 100px;

		}
		
	}

	header {

		//border-bottom: 1px solid $white-alt;

		padding-top: 20px;
		padding-bottom: 20px;
		padding-right: 20px;
		padding-left: 20px;
		margin-bottom: 40px;
		text-align: center;

		@include media-breakpoint-up(md) {

			padding-left: 65px;
			padding-right: 0;
			text-align: left;
			
		}

		@include media-breakpoint-up(lg) {

			margin-bottom: 0;

		}

		@include media-breakpoint-up(xxl) {

			padding-top: 0;
			padding-bottom: 0;
			padding-left: 95px;
			margin-bottom: 0;

		}

		.title {

			color: $brand;
			text-transform: uppercase;
			font-size: 30px;
			letter-spacing: 0.2em;

			margin-bottom: 0;

			@include media-breakpoint-up(xl) {

				font-size: 45px;
				line-height: 80px;

			}

			@include media-breakpoint-up(xxl) {

				line-height: 145px;

			}

		}

	}

	.block-half-diag.block-half-diag--alt.alt-small {

		@include media-breakpoint-up(xxl) {

			margin-bottom: 0;
			
		}

	}

}

.service-block.post-content {

	margin-top: 45px;
 	margin-bottom: 45px;

 	
}
.service-block-padding {

	@include media-breakpoint-up(md) {

		padding-left: 100px;
		padding-right: 100px;

	}
	
}

// Service Blocks
.service-block.block-grid {

	.grid-item {

		position: relative;
		overflow: hidden;
		text-align: center;

		&:before {

			content: '';
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			width: 100%;
			height: 100%;
			background: rgba($black, 0.3);

		}

		&:hover {

			.item-bg {
				
				transform: scale(1.1);

			}

		}

	}

	.item-content {

		position: relative;
		z-index: 1;
		padding-top: 50px;
		padding-bottom: 50px;

		@include media-breakpoint-up(lg) {

			padding-top: 130px;
			padding-bottom: 130px;

		}

		.title {

			color: $white;
			font-family: $font-secondary;
			font-size: 35px;
			margin-bottom: 40px;

			@include media-breakpoint-up(lg) {

				font-size: 60px;

			}

		}

	}

	.item-bg {

		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		width: 100%;
		height: 100%;

		position: absolute;
		top: 0;
		left: 0;

		transform: scale(1);
		transition: transform 0.4s ease-in-out;

	}

}

.service-block.block-grid-text {

	//display: grid;
	grid-template-columns: 100%;
	margin-top: 60px;

	@include media-breakpoint-up(md) {

		grid-template-columns: 50% 50%;

	}

	.grid-item {

		margin-bottom: 60px;

		@include media-breakpoint-up(xl) {

			margin-bottom: 120px;

		}

	}

	.item-content {

		text-align: center;

		@include media-breakpoint-up(sm) {

			text-align: left;

		}

		@include media-breakpoint-up(lg) {
			
			padding-right: 50px;

		}

		@include media-breakpoint-up(xxl) {

			padding-right: 140px;
			
		}

		.title {

			font-size: 28px;
			line-height: 38px;
			color: $brand;
			text-transform: uppercase;
			letter-spacing: 0.1em;
			margin-bottom: 10px;
		}

		.btn {

			margin-top: 20px;

			@include media-breakpoint-up(lg) {

				margin-top: 40px;

			}

		}

		p {

			line-height: 1.8;

		}

	}

}

.service-block.block-content {

	margin-bottom: 50px;

	.container-fluid {

		@include media-breakpoint-up(xxl) {

			padding-right: 0;
		}

	}

	.content {

		margin-bottom: 30px;
		padding-top: 30px;

		@include media-breakpoint-up(md) {

			margin-bottom: 60px;

		}

		@include media-breakpoint-up(xxl) {

			padding-right: 60px;
			margin-bottom: 0;
			padding-top: 50px;

		}

	}

	h3 {

		font-size: 28px;
		text-transform: uppercase;
		letter-spacing: 0.1em;
	    line-height: 1.5;
	    margin-bottom: 20px;

	}

	.image-no-video {

		@include media-breakpoint-up(xxl) {

			margin-top: 50px;
			
		}

	}

}

.embed-wrap {

	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	width: 100%;
	height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    &:after {

    	content: '';
    	position: absolute;
    	top: 0;
    	left: 0;
    	background: rgba(0, 0, 0, 0.4);
    	width: 100%;
    	height: 100%;

    }

    .embed-video-play {

    	position: absolute;
		top: 50px;
		display: inline-block;
		text-align: center;
		width: 100%;
		z-index: 5;

		position: absolute;
	    top: 50%;
	    right: 0;
	    left: 0;
	    transform: translateY(-50%);
	    width: auto;
	    margin-left: auto;
	    margin-right: auto;
			

	    &:hover {

	    	a:before {

	    		transform: translate(-50%,-50%) scale(1.3);

	    	}

	    	.icon {

	    		transform: scale(0.8);
	    		
	    	}

	    }

	    a {

	    	display: block;

	    	&:before {

	    		content: '\e909';
	    		@include icons;
				font-size: 90px;
				color: $white;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: 1;
				transition: transform .4s cubic-bezier(0.42, 0, 0, 1);

				@include media-breakpoint-up(md) {

					font-size: 130px;

				}

	    	}

	    }

	    .icon {

			font-size: 180px;
			color: rgba(0, 0, 0, 0.1);
			display: block;
			transform: scale(1);
			transition: transform .4s cubic-bezier(0.42, 0, 0, 1);	
			color: $white;
			opacity: 0.1;

			@include media-breakpoint-up(md) {

				font-size: 240px;

			}

			@include media-breakpoint-up(lg) {

				font-size: 360px;

			}

	    }

    }

}