/* ==========================================================================

   FOOTER

========================================================================== */

footer.primary {

    .title {

        font-size: 15px;
        line-height: 25px;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        margin-bottom: 34px;
        text-align: center;

        @include media-breakpoint-up(md) {
            font-size: 18px;
            line-height: 1.8em;
            margin-bottom: 20px;
        }

        @include media-breakpoint-up(lg) {
            text-align: left;
        }

        @include media-breakpoint-up(xl) {
            font-size: 28px;
            margin-bottom: 0;
        }
    }

    p {

        font-size: 12px;
        line-height: 25px;

        @include media-breakpoint-up(lg) {

            font-size: 15px;
            line-height: 30px;

        }

    }

    .consultation {

        align-items: center;
        text-align: center;
        margin-bottom: 50px;

        @include media-breakpoint-up(lg) {

            text-align: left;
            display: flex;
            justify-content: space-between;

            margin-top: 60px;
            margin-bottom: 60px;

        }

        .title {

            @include media-breakpoint-up(lg) {

                padding-right: 60px;
                flex: 1;

            }

        }


        .btn {

            @include media-breakpoint-up(lg) {

                flex: 1 0 auto;
                max-width: 380px;

            }

        }

    }

    .newsletter {

        background:$grey-darker;
        padding: 32px 0;

        @include media-breakpoint-up(lg) {

            padding: 55px 0;

        }
        
        .newsletter-option {
            display: inline-block;
            padding-right: 20px;
            padding-bottom: 15px;

            &.error {
                label {
                    color:$danger;
                    &:after {
                        content:'*';
                    }
                }
            }

            &__input {
                vertical-align: middle;
            }

            &__label {
                color: white;
                padding-left: 10px;
                margin-bottom: 0;
                vertical-align: middle;
            }
        }

        .top-title {
            text-align: center;
            font-size: 18px;
            letter-spacing: 2px;
            color: #e4e4e4;

            @include media-breakpoint-up(lg) {
                text-align: left;
            }
        }

        .title {

            @include media-breakpoint-up(xxxl) {

                padding-right: 160px;

            }

        }

        p {

            color: #BFBFBF;
            text-align: center;
            margin-bottom: 0;

            @include media-breakpoint-up(lg) {

                text-align: left;

            }

        }

        a {

            color: $brand;
            text-decoration: underline;

        }

        .form-wrap {

            @include media-breakpoint-up(lg) {

                max-width: 640px;

            }

        }

        .form-submit {

            position: relative;
            margin-bottom: 28px;

            @include media-breakpoint-up(md) {

                max-width: 80%;
                margin-left: auto;
                margin-right: auto;

            }

            @include media-breakpoint-up(lg) {

                max-width: 100%;

            }

            .email {

                width: calc(100% - 30px);
                height: 50px;
                color: $black;
                padding-right: 108px;
                padding-left: 15px;

                @include media-breakpoint-up(sm) {

                    height: 78px;
                    font-size: 18px;
                    padding-right: 180px;

                }

            }

            .submit {

                font-size: 10px;
                height: 50px;
                padding: 0 15px;

                position: absolute;
                top: 0;
                right: 0;

                @include media-breakpoint-up(sm) {

                    padding: 0 40px;
                    height: 78px;
                    font-size: 14px;

                }

            }

        }

    }

    .footer {

        @include media-breakpoint-up(lg) {

            margin-top: 100px;

        }

        .footer-widgets {

            display: flex;
            flex-direction: column;
            text-align: center;

            @include media-breakpoint-down(md) {

                padding-left: 0;
                padding-right: 0;

            }

            @include media-breakpoint-up(lg) {

                flex-direction: row;
                text-align: initial;

            }

            @include media-breakpoint-up(xl) {

                justify-content: space-between;

            }

        }

        .widget-logo {

            .logo {

                max-width: 135px;
                margin-top: 40px;
                margin-bottom: 20px;

                @include media-breakpoint-up(lg) {

                    max-width: 260px;
                    padding-right: 20px;
                    width: 100%;
                    margin-top: 0;
                    margin-bottom: 0;

                }

                @include media-breakpoint-up(xl) {

                    max-width: 320px;

                }

            }

            .logo-bma {

                max-width: 70px;
                margin-bottom: 30px;

                @include media-breakpoint-up(lg) {

                    max-width: 100px;

                }

                @media (min-width: 1300px) {

                    display: none;

                }

            }

        }

        .widget-nav {

            border-bottom: 1px solid rgba(#454545, 0.1);

            @include media-breakpoint-up(lg) {

                border-bottom: none;
                margin-left: 5%;
                margin-right: 5%;

            }

            @include media-breakpoint-up(xl) {

                margin-left: 0;
                margin-right: 0;

            }

            .site-links {

                border-bottom: 1px solid rgba(#454545, 0.1);
                padding-bottom: 20px;
                margin-bottom: 20px;
                word-wrap: break-word;

                @include media-breakpoint-up(lg) {

                    border-bottom: none;
                    padding-bottom: 0;
                    margin-bottom: 60px;

                }

                li {

                    text-transform: uppercase;

                    @include media-breakpoint-up(lg) {

                        display: inline-block;

                    }

                    &:first-child {

                        margin-bottom: 18px;

                        @include media-breakpoint-up(lg) {

                            margin-bottom: 0;
                            margin-right: 30px;

                        }

                        @include media-breakpoint-up(xl) {

                            margin-right: 70px;

                        }

                    }

                }
            }

            .nav-links {

                @include media-breakpoint-up(lg) {

                    display: flex;
                    flex-wrap: wrap;

                }

                li {

                    @include media-breakpoint-up(lg) {

                        display: flex;
                        flex-wrap: wrap;
                        width: 50%;

                    }

                    @include media-breakpoint-up(xl) {

                        width: calc(100% / 3);

                    }

                    &:not(:last-child) {

                        margin-bottom: 15px;

                    }

                }

                a {

                    color: $grey;
                    transition: all 0.4s ease-in-out;

                    &:hover {

                        color: $black;

                        @include media-breakpoint-up(lg) {

                            transform: translateX(5px);

                        }

                    }


                }

            }

        }

        .widget-social {

            margin-top: 20px;
            border-bottom: 1px solid rgba(#454545, 0.1);
            padding-bottom: 20px;

            @include media-breakpoint-up(lg) {

                border-bottom: none;
                margin-left: auto;

            }

            @include media-breakpoint-up(xl) {

                margin-left: 0;

            }

            .social-links {

                margin-bottom: 0;

                li {

                    display: inline-block;

                    @include media-breakpoint-up(lg) {

                        display: block;

                    }

                    &:not(:last-child) {

                        margin-right: 50px;

                        @include media-breakpoint-up(lg) {

                            margin-right: 0;
                            margin-bottom: 40px;

                        } 

                    }

                    a {
                        color:$brand;
                        transition:color .5s ease-in-out;
                        &:hover {
                            color: darken($brand,10%);
                        }
                    }

                }

                .icon {

                    font-size: 20px;

                }

            }

        }

        .copyright {

            margin-top: 20px;
            padding-top: 20px;
            padding-bottom: 20px;
            text-align: center;
            background: $grey-darker;
            color: $white;

            @include media-breakpoint-up(md) {

                text-align: left;
            }

            @include media-breakpoint-up(lg) {

                border-top: 1px solid rgba(#454545, 0.1);
                padding-top: 20px;
                margin-top: 40px;

            }

            .copyright-inner {

                margin-top: 20px;

                @include media-breakpoint-up(md) {

                    text-align: right;

                }

                @include media-breakpoint-up(lg) {

                    margin-top: 0;

                }

            }

            p {

                margin-bottom: 0;
                line-height: 22px;

            }

            a {

                color: $white;

            }

        }


    }

}

.footer-m {

    @include media-breakpoint-down(md) {

        margin-bottom: 50px!important;

    }

}
