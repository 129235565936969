.adviser {
  &--dpm {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: relative;
    margin: 0.75rem 0;
    overflow: hidden;

    @include media-breakpoint-down(lg) {
      height: 80vh;
    }

    @include media-breakpoint-down(sm) {
      margin: 2.5rem 0 0;
      height: 400px;
    }

    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 101%;
      background-color: $background-light;

      @include media-breakpoint-up(lg) {
        left: 35%;
      }

      @include media-breakpoint-up(sm) {
        transform-origin: bottom left;
        transform: skew(-30deg);
      }

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &__image {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      &__container {
        width: 70%;
        height: 100%;

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }

    &__content {
      width: 30%;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      position: absolute;
      padding: 2rem;
      line-height: 2rem;

      @include media-breakpoint-up(md) {
        right: 7%;
      }

      @include media-breakpoint-down(md) {
        width: 40%;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
        height: 100%;
        margin-left: 0;
        top: 10px;
        padding-bottom: 0;
        background-color: $background-light;
      }

      &__title {
        font-size: 18px;
        color: $dark-grey;
        letter-spacing: 3px;
        text-transform: uppercase;
        margin-bottom: 2rem;
      }

      &__desc {
        margin-bottom: 1.5rem;
      }

      &__btn {
        @include media-breakpoint-down(sm) {
          left: -50px;
          padding: 1rem;
        }
      }
    }
  }
}