/* ==========================================================================
   
	CONTENT IMAGE BLOCK

========================================================================== */

.content-image {

	justify-content: center;
	align-items: center;
	margin-bottom: 60px;
	margin-top: 60px;

	@include media-breakpoint-up(lg) {
    	margin-top: 80px;
		margin-bottom: 80px;

	}

	.title {
		color:$grey-darker!important;
	}

	.image {
		
		text-align: center;
		margin-bottom: 40px;

		@include media-breakpoint-up(lg) {

			margin-bottom: 0;

		}

		img {

			max-height: 600px;

		}

	}

}