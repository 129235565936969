/* ==========================================================================
   
   FORMS

========================================================================== */

form {

	input {

		&:focus {

			outline: none;

		}

	}
	
}