.skewed-image-text {
  position: relative;

  &.white {
    background-color:$white;
  }

  &.light-grey {
    background-color:$light-grey;
  }  

  &.dark-grey {
    background-color:$grey-darker;

  }  

  &.brand {
    background-color:$brand;

    .icons-inner-single__image {
      &:before { 
        color:$light!important;
      }
    }
  }     

  &.dark-grey,
  &.brand {
    .rightside-layout-textarea__hdr,
    .rightside-layout-textarea__para,
    .icons-inner-single__title {
      color:$light!important;
    }
  }

  .leftside-layout {
    transform-origin: top right;
    overflow:hidden;
    height:100%;
    opacity: 0.25;
    transform: skew(-30deg) translateX(-37.5%);

    @include media-breakpoint-only(md) {
      width: 125%;
    }

    @include media-breakpoint-up(md) {
      transform: skew(-30deg);
      height:750px;
      opacity: 1;
    }

    @include media-breakpoint-up(xxl) {
      height:830px;
    }

    .leftside-layout-image {
      @include background-cover();
      height: 100%;
      transform: skew(30deg);
      transform-origin: top right;
    }
  }

  .rightside-layout {
    max-width: 90%;
    padding-top: 100px;
    padding-bottom: 100px;  

    @include media-breakpoint-up(md) {
      max-width: 1000px;
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 40px;  
    }

    @include media-breakpoint-up(lg) {
      padding-right: 0;
      transform: translateX(-40px);
    }

    @include media-breakpoint-up(xxl) {
      transform: translateX(0px);      
    }

    .rightside-layout-textarea {
      margin-left: auto;
      margin-right: auto; 
      max-width: 100%;
      text-align: center;

      @include media-breakpoint-up(md) {
        text-align: left;
      }

      @include media-breakpoint-up(xxl) {
        max-width: 90%;
      }
      
      &__hdr {
        text-transform: uppercase;
        letter-spacing:5px;
        color:$brand;
        max-width: 700px;
        font-size: 28px;
        line-height: 1.5;

        @include media-breakpoint-up(md) {
          font-size:32px;
        }

        @include media-breakpoint-up(lg) {
          font-size:37px;
        }

        @include media-breakpoint-up(xl) {
          font-size:40px;
        }

        @include media-breakpoint-up(xxl) {
          font-size:45px;
        }
      }

      &__para {
        color:$text-color;
        margin-left: auto;
        margin-right: auto;
        max-width: 90%;

        @include media-breakpoint-up(sm) {
          max-width: 80%;
        }

        @include media-breakpoint-up(md) {
          max-width: 75%;
          margin-left: 0;
          margin-right: 0;
        }

        p {
          line-height: 2;
        }
      }

      .icons-inner {
        max-width: 700px;
        padding-top: 25px;
        padding-bottom: 25px;

        @include media-breakpoint-up(sm) {
          padding-top: 50px;
          padding-bottom: 50px;
        }

        @include media-breakpoint-up(xxl) {
          padding-top: 75px;
          padding-bottom: 75px;
        }

        .col-sm {
          @include media-breakpoint-down(lg) {
            padding-left: 7.5px;
            padding-right: 7.5px;
          }
        }

        .icons-inner-single {
          padding-bottom: 25px;
          padding-top: 25px;

          @include media-breakpoint-up(sm) {
            padding-bottom: 0;
            padding-top: 0;
          }

          &__image {
            color:$brand;
            display: inline-block;
            vertical-align: middle;

            &:before { 
              font-size:40px;

              @include media-breakpoint-up(xl) {
                font-size:45px;
              }
            }
          }

          &__title {
            text-transform: uppercase;
            display: inline-block;
            transform: translateY(4px);
            font-size:16px;
            letter-spacing: 1px;
            color:$dark;
            width: 90px;
            padding-left: 10px;

            @include media-breakpoint-up(md) {
              width: 75px;
            }

            @include media-breakpoint-up(lg) {
              width: 90px;
            }

            @include media-breakpoint-up(xl) {
              letter-spacing:3px;
              font-size:17px;
            }

            @include media-breakpoint-up(xxl) {
              font-size:20px;
            }
          }
        }
      }

      .ctas {
        margin-left: auto;
        margin-right: auto;
        max-width: 90%;
        padding-top: 40px;

        @include media-breakpoint-up(sm) {
          max-width: 80%;
        }

        @include media-breakpoint-up(md) {
          max-width: 75%;
          margin-left: 0;
          margin-right: 0;
        }    
      }
    }
  }

  .bootstyle1 {
    @include media-breakpoint-down(sm) {
      position:absolute;
      padding: 0;
      height:100%;
      width:100%;
      transform: translateX(15px);
    }
  }

  &.switch-view {

    .bootstyle2 {
      justify-content: flex-end;

      .rightside-layout {
        max-width: 100%;

        @include media-breakpoint-down(md) {
          padding-right: 0;
        }

        @include media-breakpoint-up(md) {
          transform: translateX(80px);
        }

        @include media-breakpoint-up(xl) {
          max-width: 90%;
          transform: translateX(40px);
        }

        @include media-breakpoint-up(xxl) {
          max-width: 75%;
          transform: translateX(0px);
        }

        .rightside-layout-textarea {
          &__hdr,
          .icons-inner {
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }

    .leftside-layout {
      transform: skew(30deg) translateX(37.5%);

      @include media-breakpoint-up(md) {
        transform: skew(30deg);        
      }

      .leftside-layout-image {
        transform: skew(-30deg);  
        transform-origin: top left;      
      }
    }
  }
}
