/* ==========================================================================
   
   GLOBAL

========================================================================== */

body {

    background: $white;
    color: $text-color;

    font-family: $font-base;

    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;

}

.wrap {
    position: relative;
    overflow: hidden;

    &.callout-open {
        padding-top: 80px;
    }
}

.container-fluid {

    max-width: 1800px;
    padding-left: 5vw;
    padding-right: 5vw;

    &.full {
        max-width: none;
        padding-left: 0;
        padding-right: 0;
    }

}

img {

    max-width: 100%;
    height: auto;

}

// Util
.no-pad-md {

    @include media-breakpoint-down(lg) {

        padding-left: 0;
        padding-right: 0;

    }

}

// slick
.slick-dotted.slick-slider {
    margin-bottom: 0;
}

.slick-dots {

    li {

        background: transparent;
        border: 1px solid $white;
        border-radius: 100%;
        width: 10px;
        height: 10px;

        &.slick-active {

            background: $white;

        }

    }

    button {

        &:before {

            display: none;

        }

    }

}

.vertical {
    display: flex;
    align-items: center;
    justify-content: center;
}

.remove-right-spacing {

    @include media-breakpoint-up(sm) {
        padding-right: 0;
        margin-right: 0;
    }
}

.remove-left-spacing {

    @include media-breakpoint-up(sm) {
        padding-left: 0;
        margin-left: 0;
    }
}

.filename {
    text-align: left;
    display: block;
    padding-top: 10px;
    margin-left: -15px;
    font-size: 14px;
}

.wufoo-loading {
  text-align: center;
  padding: 5rem;
}