/* ==========================================================================
   
   HALF DIAGONAL - similar to services in block-services.scss

========================================================================== */

body.page.home .block-half-diag {

	.title-float {
		width: 100vw;
	    position: relative;
		z-index:5;
	    margin-top: -50px;
	    margin-left: -58%;
	    margin-bottom: 90px;
	    padding-right: 60%;	

	    @include media-breakpoint-up(xl) {

	    	margin-left: -75%;
		    padding-right: 100%;	

	    }	

	}

}

.block-half-diag {
	
	position: relative;
	margin-bottom: 40px;

	@include media-breakpoint-up(lg) {
	
		display: flex;
		flex-direction: row;
		align-items: center;

		margin-bottom: 0;

	}

	.title {

		font-size: 15px;
		color: $brand;
		text-transform: uppercase;
		letter-spacing: 0.2em;
		display: none;

		@include media-breakpoint-up(md) {

			font-size: 32px;
			margin-bottom: 30px;

		}

		@include media-breakpoint-up(lg) {

			display: block;

		}

		@include media-breakpoint-up(xl) {

			font-size: 38px;
			line-height: 52px;
			margin-bottom: 35px;

		}

		@include media-breakpoint-up(xxl) {

			font-size: 45px;
			line-height: 70px;
			margin-bottom: 45px;

		}

		&--mobile {

			display: block;
			text-align: center;
			margin-bottom: 40px;

			@include media-breakpoint-up(lg) {

				display: none;

			}

		}

	}

	.block-banner {

		position: relative;

		@include media-breakpoint-up(xl) {

			width: 75%;

		}

		&.banner--news {

			@include media-breakpoint-up(lg) {

				width: 55%;

			}

			@include media-breakpoint-down(md) {

				position: absolute;
				left: 0;
				right: 0;
				height: 100%;
				width: 35%;
    			margin-left: -38%;

			}

			.banner-wrap {

				transform: skew(-30deg);

				@include media-breakpoint-down(md) {

					min-height: auto;
					height: 100%;
					overflow: hidden;

				}

				@include media-breakpoint-up(lg) {

				    margin-left: -80%;
    				margin-right: 42%;

				}

				@include media-breakpoint-up(xl) {
					
					margin-left: -50%;

				}

			}

			.banner-inner {

				@include media-breakpoint-down(lg) {

					transform: skew(30deg);

				}

				&:after {

					display: none;
					
				}

				.banner-bg {

					@include media-breakpoint-down(md) {

						margin-left: 80%;
						background-position: right;

					}

				}

			}

			.banner-logo {

				position: absolute;
				top: 50%;
				left: calc(50% - 130px);
				transform: translateY(-50%);
				max-width: 260px;
				z-index: 1;

				@include media-breakpoint-down(md) {

					display: none;

				}

			}

			.all-news-link {

				position: absolute;
				top: 8%;
				right: 0;
				left: calc(100% + 5vw);
				width: calc(100vw);
				text-align: left;
				z-index: 3;
				text-transform: uppercase;
				color: $brand;

				@include media-breakpoint-up(md) {

					top: 4%;

				}

				@include media-breakpoint-up(lg) {

					left: calc(50% - 130px);
					right: auto;
					width: auto;
					color: $black;

				}

				&:before {

					display: none;

					@include media-breakpoint-up(md) { 

						display: inline-block;

					}

				}

			}

		}

		.banner-wrap {

			opacity: 0;
			transition: opacity 0.4s ease-in-out;

			&.aos-animate {

				opacity: 1;

			}

			@include media-breakpoint-up(lg) {

				min-height: 680px;
				margin-left: -25%;
    			margin-right: 35%;
    			margin-bottom: 0;

			}

			@include media-breakpoint-up(xl) {

				min-height: 800px;
				margin-left: -30%;

			}

		}

		.banner-inner {

			&:after {
			
				left: -20%;
				right: auto;
				width: 300px;
				height: 60%;

				@include media-breakpoint-down(md) {

					display: none;

				}

				@include media-breakpoint-up(xl) {

					left: 8%;

				}

			}

			.banner-bg {

				@include media-breakpoint-up(xl) {

					height: 100%;

				}

			}

		}

	}

	.block-content {

		@include media-breakpoint-up(sm) {

			max-width: 70%;
			margin-left: auto;
			margin-right: auto;

		}

		@include media-breakpoint-up(lg) {

			max-width: 50%;
			margin-left: 0;
			margin-right: 0;

		}

		@include media-breakpoint-up(xl) {

			display: flex;
			flex-direction: row;
			align-items: center;
			margin-right: -30%;
			margin-left: -5%;
			max-width: 1100px;
			width: 40%;

		}

		.content {

			width: 100%;

			@include media-breakpoint-down(md) {

				text-align: center;

			}

			&.content-small {

				.title {

					font-size: 28px;
					line-height: 1.4;

				}

			}

		}

		p {

			text-align: left;
			line-height: 1.5;

		}

		.btn {

			margin-top: 30px;

		}

		.icon-list {

			@extend .list-unstyled;

			&.icon-list--alt {

				display: flex;
				flex-direction: column;
				margin-bottom: 30px;

				@include media-breakpoint-up(lg) {

					flex-direction: row;
					margin-top: 70px;
					margin-bottom: 60px;

				}

				li {

					margin-bottom: 0;

					@include media-breakpoint-up(lg) {

						&:not(:last-child) {

							margin-right: 30px;
							margin-bottom: 0;

						}

					}
					
					.icon {

						@include media-breakpoint-up(lg) {

							margin-right: 30px;
							margin-bottom: 0;
							align-items: center;

						}

						@include media-breakpoint-up(xxxl) {

							margin-right: 30px;

						}

					}

					.text {

						text-transform: uppercase;
						letter-spacing: 0.1em;

					}

				}

				& + p {

					@include media-breakpoint-up(lg) {

						margin-bottom: 60px;
						
					}

				}

			}

			li {

				display: flex;
				flex-direction: column;
				align-items:center;

				@include media-breakpoint-up(lg) {

					flex-direction: row;

				}

				&:not(:last-child) {

					margin-bottom: 25px;

				}

				.icon {

					margin-bottom: 15px;
					text-align:center;
					display:block;
					position:relative;
					margin-right: 20px;

					@include media-breakpoint-up(xxxl) {
						//margin-right: 90px;
					}

					img {

						max-width: 42px;
						max-height: 40px;
						display:block;
						@extend .mx-auto;
					}

				}

				.text {
					@include media-breakpoint-up(lg) {
						//margin-right: 60px;
						width:80%;
					}
				}

				.text p {

					margin-bottom: 0;

					@include media-breakpoint-down(md) {

						text-align: center;

					}

				}

			}

		}

		.how-it-works {

			&:first-of-type {

				@include media-breakpoint-down(md) {

					margin-top: 30px;

				}

			}

			&:not(:last-of-type) {

				margin-bottom: 40px;

			}

			.title {

				position: relative;
				font-size: 15px;
				line-height: 1;
				margin-bottom: 20px;
				
				@include media-breakpoint-up(lg) {

					line-height: 1.5;

				}

				&:before {

					content: '';
				    height: 2px;
				    width: 20px;
				    display: inline-block;
				    background: $brand;
				    position: relative;
				    top: -5px;
				    margin-right: 10px;

				    @include media-breakpoint-up(lg) {

			    	    margin-bottom: 5px;
					    display: block;

				    }

				    @include media-breakpoint-up(xl) {

						width: 50px;
						margin-right: 40px;
						display: inline-block;
					    margin-bottom: 0;

				    }

				}

			}

		}

	}

	// Content within diagonal
	&.block-half-diag--alt {

		@include z-index(aboveHero);

		&.service-block {

			&.alt-small {

				margin-bottom: 30px;
				
			}

			.block-content {

				@include media-breakpoint-only(lg) {

					max-width: 100%;
					padding-top: 30px;

				}

			}

		}

		&.alt-small {

			margin-bottom: 90px;

			@include media-breakpoint-between(lg, xl) {

				flex-direction: column;
				margin-left: -5vw;
				margin-right: -5vw;

			}

			.block-banner {

				@include media-breakpoint-between(lg, xl) {

					width: 100%;
					margin-left: 0;

				}

				.banner-wrap {
					
					background: $brand;

					@include media-breakpoint-up(sm) {

						min-height: 300px;

					}

					@include media-breakpoint-up(lg) {

						margin-right: 40%;

					}

					@include media-breakpoint-up(xl) {

						min-height: 560px;

					}

					@include media-breakpoint-between(lg, xl) {

						transform: skew(0deg);
						min-height: 300px;
						margin-left: 0;
						margin-right: 0;

					}

					.btn{
						@extend .btn--white;
					}

				}

				.banner-inner {

					@include media-breakpoint-between(lg, xl) {

						transform: skew(0);
						width: 100%;

					}

					.title,
					p {

						color: $white;

					}

					.title {

						font-size: 28px;

					}

					.block-content {

						@include media-breakpoint-between(lg, xl) {

							margin-left: 0;	
							padding-left: 5vw;
							padding-right: 5vw;

						}

						.content {

							@include media-breakpoint-between(lg, xl) {

								max-width: 100%;

							}

						}

					}

				}

				.banner-bg {

					background: $brand;

				}

			}

			.block-content {

				@include media-breakpoint-up(xl) {

				

				}

				@include media-breakpoint-between(lg, xl) {

					width: 100%;
					margin-right: 0;
					margin-left: 0;
					padding-left: 5vw;
					padding-right: 5vw;

				}

			}

		}

		.title {

			font-size: 20px;
			display: block;

			&.lg {

				font-size: 28px;
				line-height: 1;

			}

		}

		.block-content {

			margin-top: 30px;

			@include media-breakpoint-up(sm) {

				max-width: 100%;

			}

			@include media-breakpoint-only(lg) {

				max-width: 34%;

			}

			@include media-breakpoint-up(xl) {

				margin-top: 40px;
				margin-left: -12%;
				
			}

		}

		.block-content-img {

			position: absolute;
			top: 5%;
			bottom: 0;
			right: -10%;

			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			width: 100%;
			height: 90%;

			transform: translateX(-40px);
			transition: transform 0.6s ease-in-out;

			&.aos-animate {

				transform: translateX(0);

			}

			@include media-breakpoint-down(md) {

				display: none;

			}

		}

		.block-banner {

			@include media-breakpoint-only(lg) {

				width: 100%;

			}

			@include media-breakpoint-up(xl) {

				width: 85%;

			}

			.banner-wrap {

				display: flex;
				align-items: center;
				background: #F4F6FB;
				z-index: 1;
				opacity: 1;

				@include media-breakpoint-only(lg) {

				    margin-left: -33%;
    				margin-right: 33%;

				}

				&.bg-brand {

					background: $brand;

					.title,
					p {

						color: $white;

					}

				}

				@include media-breakpoint-up(lg) {

					margin-left: -30%;
					margin-right: 30%;

				}

			}

			.banner-inner {

				position: static;

				&:after {

					display: none;

				}

				.title {

					font-family: $font-base;
					color: $brand;
					margin-bottom: 30px;

					@include media-breakpoint-up(xxl) {
						width: 150%;
						font-size: 45px;

					}

				}

				.block-content {

					position: relative;
					z-index: 1;
					margin-left: 0;
					margin-right: 0;
					width: auto;
					margin-top: 0;
					padding: 50px 9vw 50px 5vw;

					@include media-breakpoint-only(lg) {

						margin-left: 36%;
						max-width: 100%;

					}

					@include media-breakpoint-up(xl) {

						margin-left: 41%;
						padding-left: 5vw;
						padding-right: 0;

					}

					.content {

						@include media-breakpoint-up(xl) {

							max-width: 70%;
							
						}

					}

					p {

						@include media-breakpoint-only(lg) {

							padding-right: 80px;

						}

						@include media-breakpoint-up(xl) {

							line-height: 1.5;

						}

					}

				}

			}

			.banner-bg {

				background-color: #F4F6FB;

				&.bg-brand {

					background-color: $brand;

				}

			}
			
		}

	}

	&.block-half-diag-numbered {

		margin-top: 0;
		position: relative;

		&.last {

			margin-bottom: 0;

		}

		@include media-breakpoint-up(xl) {
			margin-bottom: 70px;

		}

		&.first {

			margin-top: 40px;

			@include media-breakpoint-up(xl) {

				margin-top: 0!important;

			}

		}

		&.alt {

			.block-banner {

				@include media-breakpoint-up(lg) {

					order: 3;
					margin-left: 0;
					margin-right: -13%;

				}

				.banner-wrap {

    				@include media-breakpoint-up(lg) {

					    margin-left: 34%;
					    margin-right: -66%;

    				}

    				@include media-breakpoint-up(xl) {

					    margin-left: 25%;
	    				margin-right: -35%;

    				}

    				.banner-inner {

    					@include media-breakpoint-up(lg) {

	    					margin-left: -45%;

    					}

    					@include media-breakpoint-up(xl) {

	    					margin-left: -40%;

    					}

    					.banner-bg {

    						@include media-breakpoint-up(lg) {

	    						margin-left: 20%;
	    						margin-right: 0;

    						}

    					}

    				}

				}

			}

			.block-content {

				order: 2;

			}

			.block-number {

				order: 1;
				margin-left: 0;
				margin-right: 8%;

			}

		}

		// Overlap Top
		&.top {

			@include media-breakpoint-up(xl) {

				align-items: flex-start;
				margin-bottom: -150px;

			}

			.block-banner {

				@include media-breakpoint-up(lg) {

					margin-left: calc(-8% - 5px);

				}

			}

			.block-content {


				@include media-breakpoint-up(xl) {

					padding-top: 30px;

				}

			}

			.block-number {

				top: 22px;

			}

		}

		// Overlap Bottom
		&.bottom {

			@include media-breakpoint-up(xl) {
				
				align-items: flex-end;

			}

			&.last {

				.block-content {

					padding-bottom: 75px;

				}

				.block-number {

					transform: translateY(calc(-100% - 150px));

				}

			}

			.block-content {


				@include media-breakpoint-up(xl) {

					padding-bottom: 30px;
					//margin-right: calc(-12% - 4px);

				}

			}

			.block-banner .banner-wrap {

				@include media-breakpoint-up(lg) {

					margin-right: 22%;

				}

			}

			.block-number {

				top: 0;
				transform: translateY(calc(-100% - 115px));

			}

		}

		&.block-half-diag-service {

			@include media-breakpoint-up(lg) {

				margin-bottom: 0;
				
			}

			.title {

				font-family: $font-secondary;
				font-size: 35px;
				letter-spacing: 0;
				text-transform: capitalize; 

				@include media-breakpoint-up(md) {

					font-size: 50px;

				}

				@include media-breakpoint-up(xxl) {

					font-size: 60px;

				}

			}

			.block-content .btn {

				@include media-breakpoint-up(xxl) {

					margin-top: 40px;

				}

			}

			&:not(.alt) {

				.block-banner {

					@include media-breakpoint-up(xl) {

						margin-left: -30%;
						
					}

					@include media-breakpoint-up(xxl) {

						margin-left: -13%;
					}


					.banner-wrap {

						@include media-breakpoint-up(lg) {

						    margin-right: 30%;

						}

						@include media-breakpoint-up(xxl) {

							margin-right: 40%;

						}

					}

				}

			}


			&.alt {

				@include media-breakpoint-up(lg) {

					padding-left: 50px;
					margin-right: -80px;
					border-bottom: 1px solid $white-alt;

				}

				@include media-breakpoint-up(xxl) {
					
					padding-left: 75px;
					margin-right: -100px;

				}

				.block-banner .banner-wrap {

					@include media-breakpoint-up(lg) {

						margin-left: 30%;

					}

					@include media-breakpoint-up(xl) {

						margin-left: 45%;

					}

					.banner-inner {

						@include media-breakpoint-up(xl) {

							margin-left: -46%;

						}

						@include media-breakpoint-up(xxl) {

						 	margin-left: -43%;

						}

					}

				}

				.block-content {

					@include media-breakpoint-only(lg) {

						margin-left: -5%;

					}

				}

			}

		}

		.block-banner {

			@include media-breakpoint-up(lg) {

				margin-left: -13%;

			}

			.banner-wrap {

				@include media-breakpoint-up(lg) {
					
					margin-right: 32%;
    				margin-left: -40%;
					min-height: 600px;

				}

				@include media-breakpoint-up(xl) {

					margin-right: 28%;
					margin-left: -30%;
					
				}

				@include media-breakpoint-up(xxl) {

					margin-left: -37%;

				}

			}

			.banner-inner {

				&:after {

					display: none;

				}
				
			}

		}

		.block-content {

			@include media-breakpoint-up(xl) {

				margin-right: -10%;
				min-width: 40%;
				max-width: 40%;

			}

			&.content-right {

				@include media-breakpoint-up(xl) {

					text-align: right;

					p {

						text-align: right;

					}
					
				}

			}

		}

		.block-number {

			margin-left: 13%;

			@include media-breakpoint-down(lg) {

				display: none;

			}

			@include media-breakpoint-up(xl) {

				position: relative;
				top: -95px;

			}

			.number {

				background: $brand;
				border-radius: 100%;

				width: 85px;
				height: 85px;

				display: flex;
				justify-content: center;
				align-items: center;

				color: $white;
				font-size: 65px;
				font-family: $font-secondary;
				font-weight: $font-bold;

			}

		}

	}

}

.page-template-cdv-about-numbered {

	@include media-breakpoint-up(xl) {

		.hero .hero-content {

			padding-left: 200px;

		}
		
	}

}