/* ==========================================================================
   
   BRAND BANNER

========================================================================== */

.brand-banner {

	color: $white;
	padding: 40px 0;
	margin-bottom: 40px;

	&.bg-brand {
		background-color: $brand;
	}
	&.bg-light-grey {
		background-color:$light-grey;
		h3, p {
			color:$black;
		}
	}
	&.bg-dark-grey {
		background-color:$grey-dark;
	}

	@include media-breakpoint-up(lg) {

		padding: 60px 0;
		margin-bottom: 60px;
		
	}

	&.brand-banner-light {

		background-color: transparent;
		
		.title {

			color: $grey-darker;

		}
		
	}

	&.aos-animate {

		p:before {

			@include media-breakpoint-up(lg) {

				height: 100%;

			}

		}

	}

	.title {

		color: $white;
		font-size: 40px;
		line-height: 55px;
		text-transform: uppercase;
		margin-bottom: 20px;

		@include media-breakpoint-up(lg) {
			font-size:52px;
			line-height:1.3em;
		}

		@include media-breakpoint-up(xl) {
			font-size: 70px;
			margin-bottom: 0;
		}
	}

	p {

		font-size: 15px;
		line-height: 25px;
		padding-top: 40px;
		position: relative;

		@include media-breakpoint-up(lg) {

			padding-left: 100px;

		}

		@include media-breakpoint-up(xl) {

			font-size: 17px;
			line-height: 45px;
			padding-top: 220px;
			padding-left: 230px;

		}

		&:before {

			content: '';
			position: absolute;
			top: 0;
			height: 0;
			width: 190px;
			background: white;
			display: block;

			transition: width 1s ease-in-out;

			@include media-breakpoint-up(lg) {

				max-height: 190px;
				width: 2px;
				left: 50px;

				transition: height 1s ease-in-out 0.2s;

			}

			@include media-breakpoint-up(xl) {

				left: auto;
				
			}

		}

	}

	&.brand-banner-sm {

		@include media-breakpoint-up(lg) {

			padding: 40px 0;
		
		}

		.title {

			font-size: 28px;
			line-height: 42px;

		}

		.btn {

			@include media-breakpoint-up(lg) {

				margin-left: auto;
				
			}

		}

		.banner-inner {

			@include media-breakpoint-up(lg) {

				align-items: center;

			}
			
		}

	}

	&.brand-banner-alt {

		text-align: center;

		.title {

			margin-bottom: 30px;

			@include media-breakpoint-up(lg) {
				font-size:48px;
				line-height:1.3em;
			}

			@include media-breakpoint-up(xl) {
				font-size: 60px;
				margin-bottom: 40px;
			}

		}

		p {

			max-width: 800px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 30px;

			padding-top: 0;
			padding-left: 0;
			padding-right: 0;

			@include media-breakpoint-up(lg) {

				margin-bottom: 50px;
				
			}

			&:before {

				display: none;

			}

		}

		.banner-inner {

			display: block;

		}

	}

	&.brand-banner-post {

		margin-bottom: 0;

		.title {

			font-size: 20px;
			line-height: 1.3;
			letter-spacing: 0.1em;
			max-width: 1000px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 30px;

			@include media-breakpoint-up(lg) {
				font-size: 28px;
			}

			@include media-breakpoint-up(xl) {

				margin-bottom: 40px;

			}

		}

		.btn {

			@include media-breakpoint-up(lg) {

				min-width: 400px;
				
			}

		}

		.btn-group {

			display: flex;
			flex-direction: column;
			align-items: center;

			@include media-breakpoint-up(md) {

				flex-direction: row;
				justify-content: center;

			}

			.btn {

				width: 100%;

				@include media-breakpoint-up(md) {

					width: auto;

				}

				&:first-child {

					margin-bottom: 20px;

					@include media-breakpoint-up(md) {

						margin-right: 50px;
						margin-bottom: 0;

					}

				}

			}

		}
		
	}

	&.has-bg {

		background-color: none;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		margin-bottom: 0;

		.title {

			font-size: 28px;
			line-height: 38px;
			color: $black;
			letter-spacing: 0.1em;
			max-width:700px;
			margin-left: auto;
			margin-right: auto;

			@include media-breakpoint-up(lg) {

				font-size: 35px;
				line-height: 55px;

			}

		}
		
	}

	.banner-inner {

		display: flex;
		flex-direction: column;

		@include media-breakpoint-up(lg) {

			flex-direction: row;

		}

	}

}