@import "common/variables";
// Bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
// Slick
@import "../../node_modules/slick-carousel/slick/slick.scss";
@import "../../node_modules/slick-carousel/slick/slick-theme.scss";
// AOS
@import "../../node_modules/aos/dist/aos.css";
// jquery-ui
@import "../../node_modules/jquery-ui-dist/jquery-ui.css";
@import "../../node_modules/jquery-ui-dist/jquery-ui.theme.css";
/* GLOBAL
========================================================================== */
@import "common/global";
@import "common/fonts";
@import "common/mixins";
@import "common/z-index";
@import "common/type";
@import "common/icons";
@import "common/animate";
@import "common/forms";
@import "common/select2";
/* COMPONENTS
========================================================================== */
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/grid";
@import "components/wp-classes";
@import "components/benefits";
@import "components/portfolios";
@import "components/hero";
@import "components/block-service";
@import "components/block-events";
@import "components/block-half-diag";
@import "components/block-news";
@import "components/block-brand-banner";
@import "components/block-discover";
@import "components/block-content-image";
@import "components/block-advisors";
@import "components/block-awards";
@import "components/block-testimonial";
@import "components/block-gallery-topics";
@import "components/block-textarea-banner";
@import "components/block-profile-listing";
@import "components/block-featured-logos";
@import "components/block-thumb-nav";
@import "components/block-bulleted-listing";
@import "components/block-bulleted-listing-alternative";
@import "components/block-title-listing";
@import "components/block-basic-banner";
@import "components/career-application";
@import "components/career-banner";
@import "components/block-logo-descriptions";
@import "components/block-textbanner-with-title";
@import "components/block-anchor-nav";
@import "components/block-featured-stats";
@import "components/event-search";
@import "components/block-text-blocks";
@import "components/block-locations";
@import "components/location-maps";
@import "components/block-image-text";
@import "components/carousel-portrait-thumbs";
@import "components/image-crossover-textarea";
@import "components/block-video-section";
@import "components/block-skewed-image-text";
@import "components/team";
@import "components/adviser";
@import "components/insights";
/* LAYOUTS
========================================================================== */
@import "layouts/header";
@import "layouts/navigation";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/service";
@import "layouts/posts";
@import "layouts/tinymce";
@import "layouts/error-page";
@import "layouts/career-type-page";
@import "layouts/cf7forms";
@import "layouts/arrange_a_talk";
@import "layouts/appointment";
@import "layouts/nonmember-callback";
@import "layouts/thank_you_pages";
@import "layouts/careers";
@import "layouts/contact";
@import "layouts/clientlogin";
@import "layouts/landing";

.progress-bar {
  background: #f01a3a;
  height: 5px;
  animation: 10s progress infinite;
}

@keyframes progress {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

.insight-pips_container {
  display: flex;
  justify-content: center;

  .insight-pips {
    width: 30%;
    display: flex;
    position: absolute;
    bottom: 0;
    z-index: 9999999999;
    justify-content: space-around;

    .pip {
      color: white;
      opacity: 0.3;
      cursor: pointer;

      &.active {
        opacity: 1 !important;
      }
    }
  }
}
