.bulleted-listing {
	text-align:center;
	background-color:$grey-darker;

	.bulleted-listing-header {
		padding-top: 100px;
		padding-bottom: 45px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.07);

		&__hdr {
			position: relative;
			color:$light;
			letter-spacing: 4px;
    		line-height: 1.3;
    		max-width: 660px;
    		margin-left: auto;
    		margin-right: auto;
		    padding-left: 30px;
		    padding-right: 30px;
		    font-size: 24px;

		    @include media-breakpoint-up(sm) {
				font-size:32px;
		    }

			@include media-breakpoint-up(md) {
				padding-left: 0;
				padding-right: 0;
			}
			
			@include media-breakpoint-up(lg) {
				font-size:42px;
			}			

			&:after { 
				content:'';
				position: absolute;
				height:2px;
				width:75px;
				margin-left:-37.5px;
				background-color:$brand;
				top: -35px;
				left:50%;
			}
		}
	}

	.bulleted-listing-tabs {
    	padding-top: 55px;
    	padding-bottom: 85px;		

		.nav {
			justify-content: center;

			li {
    			width: 50%;

				@include media-breakpoint-up(sm) {
					width: 40%;
				}

				@include media-breakpoint-up(lg) {
					width: 25%;
				}

				a {
					font-size: 22px;

					@include media-breakpoint-up(sm) {
						font-size: 28px;
					}

					@include media-breakpoint-up(lg) {
    					font-size: 35px;
					}
					position: relative;
    				text-transform: uppercase;		
    				letter-spacing: 2px;			
    				color:rgba(255, 255, 255, 0.2);

    				&:after { 
    					content:'';
    					position: absolute;
    					height:2px;
    					width:65px;
    					margin-left: -32.5px;
    					background-color:$light;
						bottom: -15px;
    					left: 50%;   
    					opacity:0; 	
    					transition: 0.5s;
    					transform:translateY(10px);
    				}

    				&.active {
    					color:$light;

    					&:after { 
    						opacity:1;
    						transform:translateY(0px);
    					}
    				}
				}
			}
		}
	}

	.inner-listing {
		padding-left: 0;
		margin-bottom: 0;
		list-style-type:none;
    	margin-left: auto;
    	margin-right: auto;		
    	padding-bottom: 20px;
    	text-align: left;

		&__item {
			text-align: left;
			font-size: 15px;
			display:inline-block;
			color:$light;
			line-height: 2.2;
			position: relative;
			padding-bottom: 40px;
			vertical-align: top;
			padding-left: 30px;
			padding-right: 30px;
			width:100%;

			@include media-breakpoint-up(xs) {
				padding-bottom: 60px;
			}

			@include media-breakpoint-up(sm) {
				width:49%;
			}

			@include media-breakpoint-up(md) {
				width: 32%;
			}

			@include media-breakpoint-up(lg) {
				width:33%;
			}

			@include media-breakpoint-up(xl) {
				padding-left: 60px;
				padding-right: 60px;
			}

			&:before { 
				content:'•';
    			position: absolute;
    			left: 15px;		

    			@include media-breakpoint-up(xl) {
    				left: 30px;		
    			}
			}
		}
	}
}

