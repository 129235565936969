/* ==========================================================================
   
   HEADER

========================================================================== */

body{
	padding-top: 84px;
	@include media-breakpoint-up(lg) {
		padding-top: 114px;
	}
}

.cookie-notice {

	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 9999;
	width: 100%;
	background: $brand;
	display: none;

	p {

		color: $white;
		text-align: right;
		margin-bottom: 0;
		padding: 10px 50px 10px 20px;

		a {

			text-decoration: underline;
			color: $white;

		}

	}

	.close {

		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 20px;

		.icon {

			font-size: 16px;
			transform: rotate(45deg);
			display: block;

			&:before {

				color: $white;

			}

		}

	}

}

header.primary {

	position: fixed;
	width: 100%;
	background: $white;
	height: 52px;
	top:0;

	@include z-index(header);

	@include media-breakpoint-up(lg) {

		height: 82px;
		//border-bottom: 1px solid rgba(#454545, 0.1);

	}

	.master-header {
		background-color:#2f2f2f;

		.master-header-links {
			color:$light;
			text-align:right;
    		padding-top: 10px;
    		padding-bottom: 10px;			

			.nav {
				justify-content: flex-end;
				padding-right: 15px;
				margin-bottom: 0!important;

				.menu-item {
					padding-right: 15px;
					padding-left: 15px;
					margin-right: 0;
					margin-bottom: 0!important;
					opacity:1!important;
					display: inline-block;
					width: auto;

					&:first-of-type {
						&:after { 
							content:'';
							position: absolute;
							right:0;
							height:100%;
							width:1px;
							background-color:$light;
						}
					}

					a {
						text-transform: none;
	    				letter-spacing: 0;
	    				color:$light;
	    				font-size:12px;

	    				&:hover {
	    					color:$brand;
	    				}
					}

					&.active {
						a {
							color:lighten($brand, 15%);
						}
					}
				}
			}
		}
	}	

	.header {
    	background-color:white;
		padding-left: 5vw;
		padding-right: 5vw;
    	//border-bottom: 2px solid #f7f7f7;
		display: flex;
		align-items: center;
		width: 100%;
		height: 100%;

		@include media-breakpoint-up(lg) {

			height: 100%;
			width: 100%;
			padding-left: 2vw;
			padding-right: 0;
			
		}

		@media (min-width: 1800px) {

			max-width: $site-width + 200;
			margin-left: auto;
			margin-right: auto;
			padding-left: 2vw;

		}

    &--callout {
      display: none;
      align-items: center;
      background-color: $brand;

      @include media-breakpoint-up(lg) {
        padding-right: 2vw;
        padding-left: 2vw;
      }

      @include media-breakpoint-down(md) {
        height: 80px;
        justify-content: center;
      }

      @media (max-width: 1199.98px) and (orientation: landscape) {
        height: 80px;
      }

      &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        color: $white;

        @include media-breakpoint-down(md) {
          flex-direction: column;
          text-align: center;
          margin-bottom: 1rem;
        }
      }

      &__text {
        margin: 0;
        padding-right: 2rem;

        @include media-breakpoint-down(md) {
          font-size: 0.75rem;
          padding: 0;
        }
      }

      &__btn {
        display: flex !important; //Override bootstrap conditional class
        align-items: center;
        height: 45px;
        transform: none !important; //Override btn class btn skew

        @include media-breakpoint-down(md) {
          display: none !important; //Override !important used for flex
        }

        span {
          color: $brand !important; //Override btn class styling
          transform: none !important; //Override btn class text skew
          position: relative;
        }
      }

      &__link {
        font-size: 0.75rem;
        font-weight: bold;
        text-decoration: underline;
        color: $white;
      }

      &__close {
		opacity: 1;
		transform: none;

        @include media-breakpoint-down(md) {
          position: absolute;
          right: 5vw;
        }

        @include media-breakpoint-down(sm) {
          padding: 0 5px 0 0;
          right: 0;
        }

        &__icon {
          font-size: 16px;
          transform: rotate(45deg);
          display: block;

          &:before {
            color: $white;
          }
        }
      }

      .slick-dots {
        display: flex;
        bottom: inherit;
        width: auto;

        @include media-breakpoint-up(lg) {
          li {
            margin: 0 8px;
          }
        }

        @include media-breakpoint-down(md) {
          justify-content: center;
          bottom: 8px;
        }
      }
    }
  }

	.logos {

		position: relative;
		display: flex;
		align-items: center;
		width: 100%;
		flex: 1 0 auto;

		@include media-breakpoint-up(lg) {

			border-bottom: none;
			width: auto;

		}

		.logo {
			
			width: 100px;

			@include media-breakpoint-up(lg) {

				width: 150px;

			}

		}

		.logo-bma {

			max-width: 70px;
			margin-left: 20px;
			position: relative;
			top: -14px;
			
			@media (max-width: 1300px) {

				display: none;

			}

		}

	}

	.info {

		@include media-breakpoint-up(lg) {

			display: flex;
			align-items: center;
		    margin-top: -30px;
		    margin-bottom: -30px;
		    height: 100%;
		    margin-left: auto;

		}

		&.active {

			> a {

				opacity: 1;
				transform: translateY(0);

				&:first-child { transition-delay: 1s; }
				&:nth-child(2) { transition-delay: 1.1s; }

				&.btn--appointment {
					
					transform: translateY(0) skew(-25deg);

				}

			}

		}

		> a {

			opacity: 0;
			transform: translateY(20px);
			transition: all 0.8s ease-in-out;
			display: block;

			@include media-breakpoint-up(lg) {

				opacity: 1;
				transform: translateY(0);
				display: inline-block;
				font-size: 12px;

			}

			@media (min-width: 1500px) {

				font-size: 14px;

			}

		}

		.btn--appointment {

			margin-top: 40px;
			padding: 17px 30px;
			transform: translateY(20px) skew(-25deg);
			//width: calc(100% - 30px);
			margin-left: auto;
			margin-right: auto;
			max-width: 380px;

			@include media-breakpoint-up(lg) {

				//padding: 38px 30px;
				margin-top: 0;
				margin-left: 28px;
				transform: translateY(0) skew(0);
				height: 100%;
				display: flex; 
				align-items: center;

				span {

					transform: skew(0);

				}
				
			}

		}

		.phone-number {

			@include media-breakpoint-only(lg) {

				display: none;

			}

			&:before {

				@include media-breakpoint-up(lg) {
					
					font-size: 15px;

				}

				@media (min-width: 1800px) {
					
					font-size: 17px;

				}

			}

		}

	}

	.primary-main {

		opacity: 0;
		visibility: hidden;
		transform: translateX(-100%);
		transition: all 0.8s ease-in-out;

		background: $white;
		position: absolute;
		left: 0;
		right: 0;
		top: 85px;
		padding: 30px 5vw;
		border-top: 1px solid rgba(#454545, 0.1);
		//border-bottom:2px solid #f7f7f7;


		@include z-index(header);

		@include media-breakpoint-up(lg) {

			opacity: 1;
			visibility: visible;

			position: static;
			transform: translateX(0);
			padding: 0;
			border-top: none;
			height: 100%;
			width: 100%;

			display: flex;
			align-items: center;

		}

		&.active-menu {

			opacity: 1;
			visibility: visible;
			transform: translateX(0);

			.btn {

				transform: translateY(0) skew(-25deg);
				opacity: 1;
				transition-delay: 1.0s;
				
			}

			.social {

				opacity: 1;
				visibility: visible;
				transform: translateY(0);

			}

		}

	}

	.social {

		list-style-type: none;
		padding-left: 0;
		margin-bottom: 0;
		margin-top: 60px;

		opacity: 0;
		visibility: hidden;
		transform: translateY(20px);
		transition: all 0.8s ease-in-out;
		transition-delay: 1.2s;

		@include media-breakpoint-up(lg) {

			display: none;

		}

		li {

			display: inline-block;
			margin-right: 55px;

			&:last-child {

				margin-right: 0;

			}
			
			.icon {

				font-size: 25px;

			}

		}

	}

}

.mobile-menu {

	display: block;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);

	z-index: 1;

	-webkit-user-select: none;
	user-select: none;
	cursor: pointer;

	@include media-breakpoint-up(lg) {

		display: none;

	}

	input {
		
		display: block;
		width: 40px;
		height: 32px;
		position: absolute;
		top: -7px;
		left: -5px;

		cursor: pointer;

		opacity: 0; /* hide this */
		z-index: 2; /* and place it over the hamburger */

		-webkit-touch-callout: none;

		&:checked ~ span {

			opacity: 1;
			transform: rotate(45deg) translate(-1px, 0);
			background: #B2B9CA;

			&:nth-last-child(2) {

				opacity: 0;
				transform: rotate(0deg) scale(0.2, 0.2);

			}

			&:nth-last-child(1) {

				transform: rotate(-45deg) translate(0, -1px);

			}

		}

	}

	span {

		display: block;
		width: 26px;
		height: 3px;
		margin-bottom: 5px;
		position: relative;

		background: #B2B9CA;
		border-radius: 3px;

		z-index: 1;

		transform-origin: 3px 0px;

		transition: transform 0.6s cubic-bezier(0.77,0.2,0.05,1.0),
		background 0.6s cubic-bezier(0.77,0.2,0.05,1.0),
		opacity 0.55s ease;

		&:first-child {

			transform-origin: 0% 0%;

		}

		&:nth-last-child(1) {

  			transform-origin: 0% 100%;
  			margin-bottom: 0;
			
		}

	}

}

