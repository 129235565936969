/* ==========================================================================
   
   TESTIMONIAL

========================================================================== */

.testimonial-slider {

	&.slick-slider {

		margin-bottom: -10px;

		&.service-block {

			@include media-breakpoint-up(xl) {

				margin-bottom: 60px;

			}

		}
		
	}

	.slick-track {
		overflow:hidden;
	}

	.slick-active.slick-current .testimonial-item {

		.content p {

			transform: scale(1);
			opacity: 1;

		}

	}

	.testimonial-item {
		
		position: relative;
		z-index: 1;
		height: 500px;

		width: 100%;

		@include media-breakpoint-up(lg) {

			height: 425px;

		}

		.content {
			display:flex;
			align-items:center;
			width: 100%;
			height: 100%;

			p {

				z-index: 2;
				max-width:80%;
				margin-left: auto;
				margin-right: auto;				
				transform:scale(0.8);

				font-family: $font-secondary;
				color: $white;
				font-size: 30px;
				line-height: 1.2;
				text-align: center;

				opacity: 0;
				transition: all 0.4s ease-in-out 0.3s;

				@include media-breakpoint-up(xl) {


					font-size: 45px;

				}

			}

			&:after {

				content: '';
				background: rgba(0, 0, 0, 0.6);
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;

			}

		}

		.bg {

			position: absolute;
			top: 0; right: 0; bottom: 0; left: 0;
			background-repeat: no-repeat;
			background-size: cover;
			height: 100%;
			width: 100%;
			overflow: hidden;

		}

	}

	.slick-dots {

		left: 0;
		right: 0;
		bottom: 30px;
		width: auto;

		@include media-breakpoint-up(xl) {

			bottom: 80px;

		}

	}

}

.testimonial-nav {

	position: absolute;
	top: 0;
	left: 30px;
	right: 30px;
	top: 50%;
	transform: translateY(-50%);

	@include media-breakpoint-down(lg) {

		display: none;

	}

	.nav-next {

		position: absolute;
		right: 0;

	}

	.icon {

		font-size: 30px;

		&:before {

			color: $white;

		}

	}

}


.testimonial-slider {
	margin-bottom: 0!important;

	+ .portrait-thumbs {
		margin-top: -7px;
	}
}


body.blog .testimonial-slider.slick-slider.service-block {
	margin-bottom: -10px;
}