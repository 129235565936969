.bullet-title-listing {
	background-color:$brand;
	padding-bottom: 50px;
	padding-top: 100px;

	&.alternative-colour-scheme {
		background-color:$grey-darker;

		.inner-header {
			&__hdr {
				&:before { 
					background-color:$brand;					
				}
			}
		}
	}

	@include media-breakpoint-up(sm) {
		padding-top: 130px;
	}

	@include media-breakpoint-up(md) {
		padding-bottom: 100px;
		padding-bottom: 0;
	}

	.inner-header {
		text-align: center;
		padding-bottom: 80px;
    	padding-left: 15px;
    	padding-right: 15px;		

		@include media-breakpoint-up(sm) {
			padding-left: 30px;
	    	padding-right: 30px;		
		}

		@include media-breakpoint-up(md) {
			padding-left: 70px;
			padding-right: 70px;
		}

		&__hdr {
			color:$light;
			margin-bottom: 0;
			position: relative;
			line-height: 1.5;
			font-size:26px;
			letter-spacing: 6px;
			text-transform: uppercase;

			@include media-breakpoint-up(sm) {
				letter-spacing: 8px;
				font-size:28px;
			}

			@include media-breakpoint-up(md) {
				font-size:32px;
			}

			@include media-breakpoint-up(lg) {
				font-size:40px;
			}

			&:before { 
				content:'';
				position: absolute;
				width:65px;
				height:2px;
				background-color:white;
				left:50%;
				margin-left:-32.5px;
				top: -30px;
			}
		}
	}

	.inner-body {
		.inner-body-list {
			padding-left: 30px;
			padding-right: 15px;

			@include media-breakpoint-up(mobile-sm) {
				padding-left: 50px;
				padding-right: 30px;
			}

    		@include media-breakpoint-up(sm) {
			    padding-left: 35px;
			    padding-right: 35px;
    		}

			.single-item {
    			max-width: none;
    			margin-right: 0;
    			padding-bottom: 75px;  
				position: relative;

				&:before { 
					content:'';
					position: absolute;
					width:10px;
					height:10px;
					position: absolute;
					top: 17px;
					border-radius:50%;
					background-color:white;
					margin-top: -5px;
					left: -25px;

					@include media-breakpoint-up(sm) {
						left:-35px;
					}
				}	    			  

    			@include media-breakpoint-up(sm) {
    				margin-left: 40px;
    				max-width: 80%;
    				margin-left: auto;
    				margin-right: auto;	 				
    				transform: translateX(15px);			
    			}

    			@include media-breakpoint-up(md) {
					padding-bottom: 115px;
    			}			

				&__hdr {
					color:$light;
					letter-spacing: 4px;
    				margin-bottom: 20px;	
    				font-size: 20px;
    				text-transform: uppercase;

    				@include media-breakpoint-up(lg) {
    					font-size: 28px;
    				}			
				}

				&__para {
					color:$light;
					font-size:15px;
					margin-bottom: 0;
					line-height: 2;		
								
					p {
						color:$light;
						font-size:15px;
						margin-bottom: 0;
						line-height: 2;
					}
				}
			}
		}
	}
}
