/* ==========================================================================
   
   POST

========================================================================== */

/* POST BOX
   ========================================================================== */
.cat-year {

    position: relative;
    margin-bottom: 20px;

    @include media-breakpoint-up(lg) {

        margin-bottom: 40px;

    }

    &:after {

        content: '';
        width: 100%;
        height: 1px;
        background: darken($white-alt, 5%);

        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

    }

    .title {

        color: $brand;
        font-family: $font-secondary;
        font-size: 30px;
        line-height: 40px;
        display: inline-block;

        position: relative;
        z-index: 1;
        background: $white-alt;
        padding-right: 30px;

        @include media-breakpoint-up(lg) {

            font-size: 35px;
            padding-right: 100px;

        }

    }

}

.post-box-small {

    margin-bottom: 30px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 60px;
    }

    @include media-breakpoint-up(xl) {
        width:105%!important;
    }

    &.post-box-event {

        .post-box-content {

            height: 100% !important;

        }

        &.last {

            max-height: 696px;;

        }

        max-height: 740px;
        overflow: hidden;

    }

    &.post-box-news {

        .post-box-content {
            position: relative;
            min-height:300px;
            height: auto;

            @include media-breakpoint-up(sm) {
                min-height:400px;
            }

            @include media-breakpoint-up(lg) {
                min-height: 400px;
            }

            @include media-breakpoint-up(xxl) {
                min-height: 380px;
            }

            .align-items-center {
                position: absolute;
                right: 0;
                left:0;
                bottom:0;
                padding:20px;
            }
        }

        p {
            //height: 50px;
            margin-bottom: 15px;
            color:$grey-alt;
            font-size:14px;
            line-height: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -moz-box-orient: vertical;
            -webkit-line-clamp: 4;
            overflow: hidden;
          }

        .title {

            @include media-breakpoint-up(xl) {
                min-height: 84px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -moz-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
            }

        }

        .view {

            @include media-breakpoint-down(md) {

                display: none;

            }

        }

        .row {
            flex-grow: 1;
            align-content: flex-end;
        }

    }

    .post-box-image {

        display: block;

        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        width: 100%;
        height: 250px;

        @include media-breakpoint-up(xl) {

            height: 280px;

        }

        @include media-breakpoint-up(xxl) {

            height: 300px;

        }

    }

    .post-box-content {

        position: relative;
        background: $white;
        height: 100%;
        padding: 30px;

        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(xxl) {

            padding: 40px 40px 30px 40px;

        }

        &:after {

            content: '';
            position: absolute;
            bottom: 0;
            right: 0;

            width: 192px;
            height: 55px;
            background: $white-alt;

            opacity: 0;
            transition: all 0.3s ease-in-out 0.1s;

        }

        @include media-breakpoint-up(xxl) {

            padding: 35px 40px 30px 40px;

        }

    }

    .title,
    .title a {

        color: black;
        font-family: $font-secondary;
        font-weight: normal;
        margin-bottom: 30px;
        font-size: 28px;

        @include media-breakpoint-up(xxl) {
            font-size: 35px;
        }

    }

    p {
        
        color: $brand-light;
        font-size: 15px;
        line-height: 1.3;

        margin-bottom: 30px;

        @include media-breakpoint-up(lg) {

            font-size: 17px;

        }

        &.event-location {

            font-size: 18px;
            color: $brand;
            letter-spacing: 0.2em;
            text-transform: uppercase;
            line-height: 28px;
            word-break: break-word;
            margin-bottom: 15px;
            min-height: 84px;

        }

    }

    .distance {

        color: $brand;
        text-align: left;
        margin-top: 20px;

        @include media-breakpoint-up(sm) {

            margin-left: auto;
            text-align: right;
            margin-top: 0;

        }

        .distance-text {
            @extend .d-md-none;
        }

    }

    .meta {

        color: $brand;
        font-size: 15px;
        margin-bottom: 20px;

    }

    .cat {

        color: $grey-darker;
        border: 1px solid $border-vlight;
        padding: 10px 30px;
        text-align: center;
        font-size: 16px;
        display: inline-block;

    }

    .view {

        color: $brand;
        font-weight: $font-bold;
        font-size: 15px;
        text-align: right;
        text-transform: uppercase;
        letter-spacing: 0.2em;

        position: relative;
        z-index: 1;
        display: block;
        margin-top: auto;

        transition: transform 0.3s ease-in-out;

    }

}

/* POST PAGE
   ========================================================================== */

.post-featured {

    display: flex;
    flex-direction: column;

    &:not(.featured-sticky) {

        background: $white;

    }

    &.featured-sticky {

        position: relative;
        z-index: 1500;
        max-height: 450px;
        max-width: 440px;

        @include media-breakpoint-down(sm) {

            display: none;

        }

        .featured-img {

            @include media-breakpoint-up(lg) {

                height: 450px;

            }

        }

    }

    @include media-breakpoint-up(lg) {

        align-items: center;
        flex-direction: row;

    }

    .featured-img { 

        width: 100%;
        height: 300px;

        @include media-breakpoint-up(lg) {

            height: 550px;
            width: 50%;

        }

        .img {

            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            width: 100%;
            height: 100%;

        }

    }

    .featured-content {

        width: 100%;
        padding: 30px 25px;

        @include media-breakpoint-up(xl) {

            width: 50%;
            padding: 44px 80px 66px 70px;

        }

        .cat-link {

            margin-bottom: 25px;

            @include media-breakpoint-up(xl) {

                margin-bottom: 30px;

            }

        }

        .title {

            font-size: 35px;
            line-height: 40px;
            font-family: $font-secondary;
            margin-bottom: 25px;
            color: $text-color;
        }

        p {

            line-height: 1.5;
            margin-bottom: 25px;

            @include media-breakpoint-up(lg) {

                margin-bottom: 35px;

            }

        }

        .sold {

            font-family: $font-base;
            font-size: 32px;
            color: $text-color;

        }

        .event-info {

            text-align: center;

            @include media-breakpoint-up(lg) {

                text-align: left;
                display: flex;
                align-items: center;

            }

            .btn {

                @include media-breakpoint-up(lg) {

                    margin-left: auto;

                }

            }

        }

    }

}

.post-content {

    line-height: 1.5;
    margin-bottom: 25px;

    @include media-breakpoint-up(lg) {

        margin-top: 25px;

    }

    &.event-content {

        margin-top: 30px;

        @include media-breakpoint-up(xl) {

            margin-top: 60px;

        }

    }

    .post-title {

        font-size: 30px;
        text-transform: uppercase;
        letter-spacing: 0.2em;
        margin-bottom: 30px;

        @include media-breakpoint-up(lg) {

            font-size: 45px;
            margin-bottom: 45px;

        }

    }

    .content-inner {

        @include media-breakpoint-up(lg) {

            padding-right: 100px;

        }

    }

    p {
        line-height: 1.5;
        margin-bottom: 25px;

    }

    &.event-content {

        .content-inner .post-share {

            display: none;

            @include media-breakpoint-up(md) {

                display: flex;

            }

        }

    }

}

.comp-sidebar {

    border: 1px solid $border-color;

    .comp-question {

        padding: 25px 50px;
        background: $brand;
        color: $white;

        @include media-breakpoint-up(xxl) {

            padding: 45px 70px;

        }

        .question-title {

            font-size: 22px;
            color: $white;
            margin-bottom: 20px;

        }

        input.question {

            background: $brand;
            border: none;
            border-bottom: 1px solid $white;
            padding-bottom: 10px;
            width: 100%;
            color: $white;

            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: $white;
            }
            &::-moz-placeholder { /* Firefox 19+ */
                color: $white;
            }
            &:-ms-input-placeholder { /* IE 10+ */
                color: $white;
            }
            &:-moz-placeholder { /* Firefox 18- */
                color: $white;
            }

            &:focus {

                outline: none;

            }

        }

    }

    .comp-form {

        padding: 50px;

        @include media-breakpoint-up(xxl) {

            padding: 70px;

        }

        p {

            margin-bottom: 0;

        }

        button {

            margin-bottom: 0;

        }

    }

    .form-message {

        line-height: 35px;
        text-align: center;
        margin-bottom: 20px;

    }

    .form-submit {

        text-align: center;

    }

}

.event-sidebar {

    border: 1px solid $grey;
    padding: 30px 40px;
    text-align: center;
    max-width: 400px;

    @include media-breakpoint-up(md) {

        padding: 50px 70px;

    }

    .title {

        text-transform: uppercase;
        font-size: 28px;
        letter-spacing: 0.1em;
        color: $text-color;
        margin-bottom: 40px;

    }

    address {

        color: $brand;
        text-transform: uppercase;
        font-size: 17px;
        letter-spacing: 0.2em;
        line-height: 30px;
        margin-bottom: 40px;

    }

    img {

        margin-bottom: 30px;

        @include media-breakpoint-up(md) {

            margin-bottom: 60px;

        }

    }

    .view-map {

        position: relative;
        font-size: 17px;
        color: $text-color;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        margin-bottom: 40px;
        display: inline-block;

        @include media-breakpoint-up(md) {

            margin-bottom: 70px;

        }

        &:before {

            content: '\e90c';
            @include icons;
            color: $brand;
            font-size: 40px;
            display: inline-block;
            margin-right: 20px;
            position: relative;
            top: 10px;

        }

    }

}

.post-share {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    @extend .list-unstyled;
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {

        justify-content: normal;
        margin-bottom: 50px;

    }

    @include media-breakpoint-up(xl) {

        margin-bottom: 0;

    }

    li {

        &:not(:last-child, .first) {

            margin-right: 40px;

            @include media-breakpoint-up(md) {

                margin-right: 50px;

            }

        }

        &.first {

            font-size: 28px;
            text-transform: uppercase;
            letter-spacing: 0.1em;
            color: #2C3946;
            width: 100%;
            text-align: center;
            margin-bottom: 20px;

            @include media-breakpoint-up(md) {

                text-align: left;
                width: auto;
                margin-right: 50px;
                margin-bottom: 0;

            }

        }

    }

    .icon {

        font-size: 25px;
        color: $brand;

    }

}

.yarpp-related {

    margin: 0 !important;

    .page-block .block-title .title {

        font-weight: inherit;
        font-size: 36px;

        @include media-breakpoint-up(xl) {

            font-size: 45px;

        }

    }

}

// Ajax Load More
.ajax-load-more-wrap {

    width: 100%;

    .alm-listing.row {

        margin-left: 0;
        margin-right: 0;

    }

}

.alm-btn-wrap {

    text-align: center;

    .alm-load-more-btn {

        min-width: 280px;

        &.done {

            display: none;

        }

    }

}

/* POST SINGLE
========================================================================== */

.entry-title {

    margin-bottom: 30px;
    max-width: 800px;

    @include media-breakpoint-up(xl) {

        margin-bottom: 40px;

    }

    .cat-link {

        margin-bottom: 30px;

        @include media-breakpoint-up(xl) {

            margin-bottom: 40px;

        }

    }

    h1 {

        font-size: 34px;
        line-height: 1.3;
        letter-spacing: 0.2em;
        text-transform: uppercase;

        @include media-breakpoint-up(xl) {

            font-size: 40px;

        }

    }

}

.single-post {
    .go-back {
        color:$black;
        font-family: $font-secondary;
        font-size:25px;
        span {
            margin-right:20px;
            transition:all .25s ease-in-out;
        }
        &:hover {
            span {
                margin-right:40px;
            }

        }
    }
}

.entry-meta {

    margin-bottom: 10px;

    @include media-breakpoint-up(lg) {

        margin-bottom: 30px;

    }

    @include media-breakpoint-up(xl) {

        margin-bottom: 50px;

    }

    .meta {

        color: $brand;
        font-size: 17px;
        display: inline-block;
        margin-bottom: 20px;

        @include media-breakpoint-up(lg) {

            margin-bottom: 0;

        }

    }

    .post-share {

        display: none;

        @include media-breakpoint-up(md) {

            display: flex;
            margin-left: auto;

        }

    }

}

.entry-content {

    margin-bottom: 50px;

    table {
        width: 100%;
    }
    p {

        line-height: 1.35;
        margin-bottom: 25px;

        & + blockquote {

            margin-top: 25px;

        }

    }

    ul, ol {

        margin-bottom: 20px;

        li {

            font-size: 15px;
            line-height: 1.6;

            @include media-breakpoint-up(xxl) {
                font-size: 17px;
            }

        }

    }

    h4 {
        line-height: 1.5;
        margin-bottom: 25px;
    }

    h5 {
        margin-bottom: 15px;
    }

    .embed-container {

        margin-top: 30px;

        @include media-breakpoint-up(xl) {

            margin-top: 30px;

        }

    }

    .post-share {

        display: flex;
        margin-top: 80px;

        @include media-breakpoint-up(md) {

            display: none;

        }

    }

    table {

        @extend .table;
        @extend .table-striped;

    }

    blockquote {

        margin-bottom: 20px;
        border-left: 3px solid #015db8;
        padding: 30px 50px 20px 30px;

        p {

            font-size: 17px;

        }

    }

}