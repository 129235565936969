/* Styles for the Featured Logos component */

.featured-logos {
    padding:20px 0;
    @include media-breakpoint-up(md) {
        padding:50px;
    }
    .title {
        color:#000;
        text-align:center;
        margin-bottom:50px;
        text-transform: uppercase;
        position:relative;
        padding-top:25px;

        &:before {
            content:'';
            width:50px;
            height:1px;
            background:$brand;
            position:absolute;
            top:0;
            left:0;
            right:0;
            margin:auto;
        }

    }

    .logo-item {
        display:block;
        padding:20px;
        text-align:center;
        .image {
            display:block;
            margin:auto;
            width:200px;
            img {
                display:block;
                width:100%;
                height:auto;
                margin:auto;
            }
        }
        .title, p {
            margin-bottom:0;
        }
        .title {
            font-size:18px;
            text-transform:uppercase;
            margin-top:30px;
        }
        p {
            font-size:15px;
            margin-top:20px;
            color:$text-color;
            padding:0 20px;
        }
    }

}