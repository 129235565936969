/* Text Blocks component styling */
.block-text {

    padding:50px 0;

    .section-title {
        text-transform:uppercase;
        color:$heading-color-dark;
        margin:0;
    }

    .social-sharing {
        @extend .d-flex;
        @extend .align-items-center;
        padding-bottom:20px;

        .social-buttons {
            @extend .ml-4;
            @extend .d-flex;
            @extend .align-items-center;
            a {
                display:block;
                font-size:24px;
                padding:0 20px;
                color:$brand;
                transition:color .5s ease-in-out;
                &:hover {
                    color: darken($brand,10%);
                }
            }
        }
    }

    .content-block {
        display:block;
        .row {
            margin-left:-40px;
            margin-right:-40px;
            border-top:rgba(#2C3946, .1) 1px solid;
            padding-top:20px;
            padding-bottom:20px;
            &:first-of-type {
                border-top:none;
            }
        }
        .col {
            padding: 20px 40px 0;
        }
        .title {
            font-size:16px;
            line-height:1.3em;
            margin-bottom:20px;
            position:relative;
            padding-top:25px;
            &:before {
                content:'';
                width:50px;
                height:1px;
                background:$brand;
                position:absolute;
                top:0;
                left:0;
            }
        }
        .content {
            ul {
                padding-left:20px;
                li {
                    padding:5px 0;
                }
            }
        }
    }

}