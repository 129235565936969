.how-it-works-wrap {
    padding: 50px 0 0 0;
    h3 {
        font-size: 18px;
        color: $black;
        text-transform: uppercase;
        margin-bottom: 35px;
        @include media-breakpoint-up(lg) {
            font-size: 28px;
        }
    }
    .how-it-works-block {
        margin-bottom: 50px;
        @include media-breakpoint-up(lg) {
            font-size: 25px;
        }
        h4 {
            color: $brand;
            font-size: 14px;
            letter-spacing: 0.1em;
            margin-bottom: 15px;
            padding-left: 25px;
            @include media-breakpoint-up(lg) {
                padding-left: 50px;
                margin-bottom: 25px;
                font-size: 18px;
            }
            &:before {
                content: "";
                display: block;
                width: 20px;
                height: 1px;
                background-color: $brand;
                position: absolute;
                left: 15px;
                top: 7px;
                @include media-breakpoint-up(lg) {
                    width: 40px;
                }
            }
        }
        p {
            font-size: 12px;
            line-height: 2.5;
            @include media-breakpoint-up(lg) {
                font-size: 17px;
            }
        }
    }
}

.talk-request-wrapper {
    padding: 50px 0;
    background-color: $white-alt;

    .checkbox-wrap {
        p {
            line-height: 1.7;
            font-size: 14px;
        }
    }

}

.how-it-works-block {
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {

        margin-top: 20px;

    }

    .description {
        margin: 50px auto;
        text-align: center;
        max-width: 500px;
        color: $brand;
    }
    h3 {
        font-size: 18px;
        color: $black;
        text-transform: uppercase;
        margin-bottom: 35px;
        @include media-breakpoint-up(lg) {
            font-size: 28px;
        }
    }
    h4 {
        color: $brand;
        font-size: 14px;
        letter-spacing: 0.1em;
        margin-bottom: 15px;
        @include media-breakpoint-up(lg) {
            margin-bottom: 20px;
        }
        span {
            width: 50px;
            display: inline-block;
            height: 50px;
            padding-top: 13px;
            border-radius: 50%;
            text-align: center;
            background-color: $brand;
            margin-right: 20px;
            img {
                width: auto;
                height: auto;
                max-width: 25px;
                max-height: 30px;
            }

        }
    }
    p {
        font-size: 12px;
        line-height: 2.5;
    }

    .form-contact-details {
        padding-bottom: 35px;
    }
    li.option {
        &:before {
            background: rgba(255, 255, 255, 0.4);
        }
    }
    
}