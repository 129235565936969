.team {
  &--dpm {
    position: relative;

    @include media-breakpoint-down(lg) {
      height: 700px;
    }

    @include media-breakpoint-down(sm) {
      min-height: 850px;
    }

    &::before {
      position: absolute;
      content: "";
      width: 95%;
      height: 100%;
      background-color: $background-light;
      transform-origin: bottom left;
      transform: skew(-30deg);
      top: 0;
      left: 5%;
      z-index: -1;

      @include media-breakpoint-down(sm) {
        width: 800px;
        left: -60vw;
        transform: skew(-40deg);
      }
    }

    &__content {
      width: 30%;
      display: flex;
      justify-content: flex-start;
      align-self: center;
      flex-direction: column;
      padding: 2rem;
      margin-left: 4rem;
      line-height: 2rem;

      @include media-breakpoint-down(md) {
        width: 40%;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
        margin-left: 0;
        top: 10px;
      }

      @include media-breakpoint-down(sm) {
        display: block;
      }

      &__title {
        font-size: 18px;
        color: $dark-grey;
        letter-spacing: 3px;
        text-transform: uppercase;
        margin-bottom: 2rem;
      }

      &__desc {
        margin-bottom: 1.5rem;
      }

      &__btn {
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }

    &__carousel {
      width: 40%;
      margin: 15vh 0 15vh auto;

      @include media-breakpoint-down(sm) {
        margin: 2rem 0 0 0;
        left: 40px;
        right: 30px;
        width: 80%;
      }

      @include media-breakpoint-up(md) {
        right: 0;
      }

      @include media-breakpoint-up(lg) {
        width: 50%;
      }

      .slick-active,
      .slick-current {
        .team--dpm__carousel__item--name,
        .team--dpm__carousel__item--content {
          display: flex;
        }
      }

      &__item {
        @include media-breakpoint-up(xl) {
          width: 60%;
        }

        @include media-breakpoint-up(lg) {
          padding: 20px;
        }

        @include media-breakpoint-down(sm) {
          width: 85vw;
        }

        &--image {
          margin-bottom: 1.5rem;
        }

        &--name {
          display: none;
          max-width: 80%;
          margin-bottom: .5rem;
          font-size: 35px;
          font-family: $font-secondary;
          color: black;
        }

        &--content {
          display: none;
          max-width: 70%;
          min-height: 80px;
        }
      }

      & .slick-list {
        @include media-breakpoint-down(sm) {
          padding: 0 !important; //Override slick settings
        }
      }

      @include media-breakpoint-down(md) {
        .slick-slide {
          margin: 0 10px;
        }

        // Fix external margins
        .slick-list {
          margin: 0 -20px;

          @include media-breakpoint-down(sm) {
            overflow: visible !important; //Override slick settings
          }
        }
      }
    }

    &__nav {
      position: absolute;
      right: 50px;

      @include media-breakpoint-up(xl) {
        top: 70%;
        right: 80px;
      }

      @include media-breakpoint-down(xl) {
        top: 65%;
        right: 80px;
      }

      @include media-breakpoint-down(lg) {
        top: 50%;
        right: 70px;
      }

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
}