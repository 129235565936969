.benefits {
  margin: 30px;

  @include media-breakpoint-up(md) {
    border-bottom: $grey 1px solid;
    margin: 60px 60px 140px;
  }
}

.benefit-item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-self: flex-start;
  margin: 2rem;

  @include media-breakpoint-down(md) {
    align-self: center;
    margin: 1.5rem 0.25rem;
  }

  &__container {
    display: flex;
    margin: 3rem 2rem 0;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      margin: 3rem 0 0;
    }
  }

  &__link {
    @include media-breakpoint-down(sm) {
      position: relative;
      min-height: 80px;
    }

    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--btn {
      bottom: -35px;

      @include media-breakpoint-down(sm) {
        left: -50px;
      }
    }
  }

  &__number {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 30px;
    height: 30px;
    font-size: 14px;
    border-radius: 50%;
    margin: 0 auto;
    padding-bottom: 4rem;

    @include media-breakpoint-up(md) {
      width: 50px;
      height: 50px;
    }

    @include media-breakpoint-down(md) {
      padding-bottom: 3rem;
    }

    &:before {
      content: "";
      width: 50px;
      height: 50px;
      position: absolute;
      background-color: $brand;
      border-radius: 50%;

      @include media-breakpoint-up(md) {
        width: 70px;
        height: 70px;
        left: auto;
      }

    }

    &__text {
      position: absolute;
      color: white;
      font-family: $font-secondary;
      font-size: 35px;

      @include media-breakpoint-down(md) {
        font-size: 20px;
      }
    }
  }

  &__title {
    font-size: 18px;
    color: $dark-grey;
    letter-spacing: 3px;
    margin-bottom: 1rem;
  }

  &__content {
    font-size: 17px;

    @include media-breakpoint-up(md) {
      width: 70%;
      align-self: center;
    }
  }
}