@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?iht0u2');
  src:  url('../fonts/icomoon.eot?iht0u2#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?iht0u2') format('truetype'),
    url('../fonts/icomoon.woff?iht0u2') format('woff'),
    url('../fonts/icomoon.svg?iht0u2#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-personal-black:before {
  content: "\e91c";
}
.icon-personal_injury_black:before {
  content: "\e917";
}
.icon-ic_domain_24px:before {
  content: "\e918";
}
.icon-ic_group_24px:before {
  content: "\e919";
}
.icon-ic_person_24px:before {
  content: "\e91a";
}
.icon-Shape:before {
  content: "\e91b";
}
.icon-ic_store_24px:before {
  content: "\e915";
}
.icon-ic_home_24px:before {
  content: "\e916";
}
.icon-phone:before {
  content: "\e914";
}
.icon-ic_settings_backup_restore_24px:before {
  content: "\e90f";
}
.icon-ic_switch_camera_24px:before {
  content: "\e910";
}
.icon-ic_toll_24px:before {
  content: "\e911";
}
.icon-ic_trending_up_24px:before {
  content: "\e912";
}
.icon-ic_grain_24px:before {
  content: "\e913";
}
.icon-location:before {
  content: "\e90c";
}
.icon-search:before {
  content: "\e90d";
}
.icon-face:before {
  content: "\e90a";
}
.icon-thumb:before {
  content: "\e90b";
}
.icon-play:before {
  content: "\e909";
}
.icon-mail:before {
  content: "\e90e";
}
.icon-prev:before {
  content: "\e900";
}
.icon-down:before {
  content: "\e901";
}
.icon-next:before {
  content: "\e902";
}
.icon-linkedin:before {
  content: "\e903";
}
.icon-building:before {
  content: "\e904";
}
.icon-speech:before {
  content: "\e905";
}
.icon-facebook:before {
  content: "\e906";
}
.icon-twitter:before {
  content: "\e907";
}
.icon-plus:before {
  content: "\e908";
}
