/* ==========================================================================

   NAVIGATION

========================================================================== */

header.primary {

  .nav {

    @extend .list-unstyled;
    padding-left: 10px;
    margin-bottom: 45px;

    @include media-breakpoint-up(lg) {

      margin-bottom: 0;
      flex-wrap: nowrap;
      flex: 1 0 auto;
      justify-content: center;

    }

    &.active-menu {

      .menu-item {

        opacity: 1;
        transform: translateX(0);

        @for $item from 1 through 10 {
          &:nth-child(#{$item}) {
            transition-delay: ($item)*0.3s;
          }
        }

      }

    }

    .menu-item {

      @extend .list-unstyled;
      display: block;
      width: 100%;

      font-size: 14px;
      font-weight: 500;
      color: #000;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      line-height: 100%;

      margin-bottom: 25px;

      opacity: 0;
      transform: translateX(20px);
      transition: all 0.6s ease-in-out;

      @for $item from 1 through 10 {
        &:nth-child(#{$item}) {
          transition-delay: ($item)*0.1s;
        }
      }

      &:last-child {

        margin-bottom: 10px;

        @include media-breakpoint-up(lg) {

          margin-bottom: 0;

        }

      }

      @include media-breakpoint-up(lg) {

        opacity: 1;
        transform: translateX(0);

        display: inline-block;
        width: auto;

        margin-right: 45px;
        margin-bottom: 0;
        font-size: 12px;

        &:last-child {

          margin-right: 0;

        }

      }

      @media (min-width: 1500px) {

        font-size: 14px;

      }

      a {

        position: relative;
        color: #000;

        &:hover {

          color: $brand;

        }

      }

      &.menu-item-has-children {

        &.active-menu {

          & > a:after {

            transform: rotate(-180deg);

          }

        }

        .drop-mobile.active-menu {

          &:after {

            transform: rotate(-180deg);

          }

        }

        & > a:after {

          @include icons;
          content: '\e901';
          display: inline-block;
          color: $brand;
          font-size: 11px;
          margin-left: 15px;
          transform: rotate(0);
          transition: transform 0.4s ease-in-out;

          @include media-breakpoint-up(lg) {

            font-size: 8px;

          }

        }

      }

      > .sub-menu {

        display: none;
        padding-top: 25px;
        padding-left: 25px;

        @include media-breakpoint-up(lg) {

          display: none !important;

        }

        a {

          &:before {

            content: '';
            position: absolute;
            top: 8px;
            left: -25px;
            background: $brand;
            height: 2px;
            width: 15px;

          }

        }

      }

    }

  }

}

.persona-dropdown {

  position: fixed;
  top: 0;
  left: 0;
  @include z-index(personaDrop);
  width: 100%;
  height: 100%;
  background: #f4f6fb;
  padding-top: 110px;

  transform: translateY(-110%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.6s ease-in-out;

  @include media-breakpoint-down(md) {

    &:not(.persona-dropdown-index) {

      display: none;

    }

  }

  &.persona-dropdown-index {

    position: static;
    margin-left: auto;
    margin-right: auto;

    text-align: center;

    .dropdown-inner {

      padding-left: 0;

    }

    .persona-dropdown-title {

      font-family: $font-secondary;
      font-size: 45px;
      color: $brand;
      display: inline-block;

    }

    .persona-list .persona-item a:after {

      right: auto;
      left: -120px;

      @media (min-width: 1450px) {

        left: -220px;

      }

    }

  }

  .full-scale-menu {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 50px;
    background-color: black;
    position: relative;
    flex-direction: column;

    .background-image {
      background-image: url('../images/menu-image.jpg');
      @include background-cover();
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      transition: 2s;
      transition-delay: 0.5s;
    }

    hr {
      width: 100%;
      border-color: #676767;
    }

    .top-menu, .bottom-menu {
      display: flex;
      justify-content: space-evenly;
    }

    .single-item {
      position: relative;
      display: inline-block;
      width: 20%;
      text-align: center;
      opacity: 0;
      transition: 1s;
      height: 140px;

      &:nth-of-type(1) {
        transition-delay: 0.6s;
      }

      &:nth-of-type(2) {
        transition-delay: 0.7s;
      }

      &:nth-of-type(3) {
        transition-delay: 0.8s;
      }

      &:nth-of-type(4) {
        transition-delay: 0.9s;
      }

      &:nth-of-type(5) {
        transition-delay: 1s;
      }

      .single-item-image {
        height: 40px;
        width: 40px;
        @include background-contain();
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;

        &:before {
          font-family: 'icomoon' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          color: #fff;
          font-size: 35px;

          /* Better Font Rendering =========== */
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        &.partnerships {
          &:before {
            content: '\e919';
          }
          //background-image: url('../images/partnerships.svg');
        }

        &.personal-injury {
          &:before {
            content: '\e91c';
          }
          //background-image: url('../images/personal-injury.svg');
        }

        &.medical {
          &:before {
            content: '\e91b';
          }
          //background-image: url('../images/medical.svg');
        }

        &.dental {
          &:before {
            content: '\e91d';
          }
        }

        &.corporate {
          &:before {
            content: '\e918';
          }
          //background-image: url('../images/corporate.svg');
        }

        &.private-client {
          &:before {
            content: '\e91a';
          }
          //background-image: url('../images/private-client.svg');
        }
      }

      &__para {
        text-transform: uppercase;
        letter-spacing: 2px;
        color: $light;
        font-size: 14px;

        @include media-breakpoint-up(xxl) {
          font-size: 18px;
        }

        &:after {
          content: "\e901";
          font-family: icomoon !important;
          color: $brand;
          position: absolute;
          bottom: -30px;
          left: 50%;
          width: 20px;
          margin-left: -10px;
          background-size: contain;
          font-size: 12px;
          transform: translateY(50px) rotate(-90deg);
          opacity: 0;
          transition: 0.5s;
        }
      }

      &__subtext {
        color: $light;
        font-size: 13px;
      }

      &:hover {

        .single-item__para {

          &:after {
            transform: translateY(0px) rotate(-90deg);
            opacity: 1;
          }
        }
      }
    }

    .bottom-menu {
      .single-item {
        width: calc(33% - 130px);
      }
      .btn {
        margin-top: 0;
        transform: translateY(0) skew(0);
        height: 60%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 0;
        font-size: $font-size;

        .icon-plus {
          font-size: 25px;
        }
      }
    }
  }

  &.active-menu {

    opacity: 1;
    visibility: visible;
    transform: translateY(0);

    .full-scale-menu {
      .background-image {
        opacity: 0.4;
      }

      .single-item {
        opacity: 1;
      }
    }

    // Animate list items
    .persona-list .persona-item {

      opacity: 1;
      transform: translateY(0);

      &:first-child {
        transition-delay: 0.6s;
      }

      @for $item from 2 through 12 {
        &:nth-child(#{$item}) {
          transition-delay: ($item)*0.15s+0.3s;
        }
      }

    }

  }

  .dropdown-inner {

    position: relative;
    padding: 0px;
    height: 100%;

  }

  .persona-list {

    list-style-type: none;
    padding: 0;
    margin: 0;

    .persona-item {

      position: relative;
      font-size: 20px;
      text-transform: uppercase;
      color: $black;
      letter-spacing: 0.2em;
      margin-bottom: 50px;

      opacity: 0;
      transform: translateY(20px);
      transition: all 0.6s ease-in-out;

      &:last-child {

        margin-bottom: 0;

      }

      &:hover a {

        &:after {

          width: 80px;
          opacity: 1;

          @media (min-width: 1450px) {

            width: 160px;

          }

        }

      }

      a {

        position: relative;
        color: $black;

        &:after {

          content: '';
          position: absolute;
          top: 50%;
          right: -120px;

          transform: translateY(-50%);
          background: $brand;
          width: 0;
          height: 2px;

          opacity: 0;
          transition: all 0.3s ease-in-out;

          @media (min-width: 1450px) {

            right: -220px;

          }

        }

      }

    }

  }
}
