.thumb-nav {
	display: none;

	@include media-breakpoint-up(md) {
		display:block;
	}

	.thumb-nav-single {
		text-align: center;
		background-color:$grey-darker;
		display:block;
		transition: transform 0.75s, background-color 0.75s, z-index 0s;
		display: flex;
		align-items: center;
		justify-content: center;				
		height:200px;

		@include media-breakpoint-up(lg) {
			height:300px;
		}

		&:hover {
    		transform: scale(1.2);
    		background-color: $brand;
    		position: relative;
    		z-index: 1;			

    		.inner-textarea {
    			transform: translateY(-20px);    		

    			&__arrow {
    				opacity:1;
    			}	
    		}
		}

		.inner-textarea {
			transition: 1.5s;

			span {
				color:$light;
				font-size:20px;
				margin-bottom: 15px;
				display: block;
			}
			
			&__para {
				text-transform: uppercase;
				color:$light;
				letter-spacing: 2px;
				font-size:14px;
				max-width: 150px;	

				@include media-breakpoint-up(lg) {
					font-size:16px;
					max-width: 160px;	
				}

				@include media-breakpoint-up(xl) {
					font-size:18px;
					max-width: none;
				}
			}

			&__arrow {
				position:absolute;
				opacity:0;
				transition: 1.5s;
    			left: 50%;
    			margin-left: -5px;					
			}
		}
	}

	.col {
		padding-left: 0;
		padding-right: 0;
	}
}
