.logo-descriptions {
    text-align: center;

    .logo-descriptions-textarea {
        position: relative;
        padding-top: 60px;
        padding-bottom: 20px;

        &:after {
            content:'';
            position: absolute;
            top:0;
            left:50%;
            height: 2px;
            width: 65px;
            margin-left:-32.5px;
            background-color:$brand;
        }

        &__hdr {
            letter-spacing: 5px;
            color:$grey-darker;
            font-size:25px;
            padding-bottom: 30px;

            @include media-breakpoint-up(sm) {
                font-size:32px;
            }
        }

        &__para {
            max-width: 800px;
            margin-left: auto;
            margin-right: auto;
            font-size: 16px;
            padding-bottom: 35px;
            margin-bottom: 0;
            line-height: 1.7;

            @include media-breakpoint-up(lg) {
                font-size: 18px;
            }

            @include media-breakpoint-up(xl) {
                font-size: 22px;
            }
        }
    }

    .logo-descriptions-inner {
        padding-bottom: 25px;

        @include media-breakpoint-up(md) {
            padding-bottom: 100px;
        }

        .logos-group {
            @include media-breakpoint-up(md) {
                border-top: 1px solid #e6e6e6;
            }
        }

        .logo-single {
            position: relative;
            max-width: 300px;
            margin-left: auto;
            margin-right: auto;
            padding-bottom: 50px;

            @include media-breakpoint-up(md) {
                height: 200px;
                padding-bottom: 0;
            }

            .logo-single-image {
                width:250px;
                height:95px;
                transition: 0.5s;
                transform: translateY(50%);
                opacity:0.5;
                @include background-contain();
                transition-delay: 0.25s;
                margin-left: auto;
                margin-right: auto;
            }

            &__para {
                font-size: 15px;
                margin-bottom: 0;
                margin-left: auto;
                margin-right: auto;
                padding-top: 10px;
                height:95px;
                opacity:0;
                transition: 0.5s;
            }

            &.no-paragraph {
                .logo-single-image {
                    opacity:1!important;
                    transform: translateY(50%)!important;
                }
            }

            &:hover {
                .logo-single-image {
                    transform: translateY(0%);
                    transition-delay: 0s;
                    opacity:1;
                }

                .logo-single__para {
                    opacity:1;
                    transition-delay: 0.25s;
                }
            }

            @include media-breakpoint-down(md) {
                &.aos-animate {
                    .logo-single-image {
                        transform: translateY(0%);
                        transition-delay: 0s;
                        opacity:1;
                    }

                    .logo-single__para {
                        opacity:1;
                        transition-delay: 0.25s;
                    }
                }
            }
        }
    }

    .bootstyle {
        padding-left: 0;
        padding-right: 0;

        @include media-breakpoint-up(md) {
            padding-top: 50px;
            padding-bottom: 50px;
        }
    }
}
