/* Styles for the video section */
.video-section {
 background-color: #000;
 padding: 20px 0;
 @include media-breakpoint-up(md) {
  padding: 50px;
 }
 .title {
  color: #fff;
  text-align: center;
  margin-bottom: 50px;
  padding-top: 50px;
  text-transform: uppercase;
  position: relative;
  letter-spacing: 2px;
  @include media-breakpoint-up(lg) {
   letter-spacing: 4px;
  }

  &:before {
   content: '';
   background: $brand;
   opacity: 1;
   width: 60px;
   height: 2px;
   position: absolute;
   margin: 0 auto;
   top: 0;
   left: 0;
   right: 0;
   display: block;
  }
 }

 .title-supporting {
  color: #fff;
  font-size: 14px;
  margin: 0 auto 50px;
  text-align: center;
  display: block;
  @include media-breakpoint-up(lg) {
   font-size: 16px;
   max-width: 80%;
  }
 }

 .video-container {
  height: 240px;
  width:100%;
  position:relative;
  overflow:hidden;
  @include media-breakpoint-up(lg) {
   height: 560px;
  }
  .video-overlay {
   position:relative;
   display:flex;
   align-items: center;
   justify-content: center;
   height:100%;
   z-index:1;

   .play-icon {
    position:relative;
    z-index:2;
    display:flex;
    align-items: center;
    justify-content: center;
    color:#fff;
    width:200px;
    height:200px;

    @include media-breakpoint-up(lg) {
     width:300px;
     height:300px;
    }

    > span {
     font-size:60px;
     @include media-breakpoint-up(lg) {
      font-size:100px;
     }

     &.large-faded {
      position:absolute;
      top:0;
      left:0;
      bottom:0;
      right:0;
      opacity:.2;
      font-size:200px;
      @include media-breakpoint-up(lg) {
       font-size:300px;
      }
     }
    }
   }

   .video-poster {
    position:absolute;
    width:100%;
    height:100%;
    display:block;
    background-size:cover;
    background-position:center center;
    background-repeat: no-repeat;
    top:0;
    left:0;
    bottom:0;
    right:0;
   }
  }
  .video-embed {
   height: 240px;
   position:absolute;
   top:0;
   left:0;
   right:0;
   bottom:0;
   z-index:0;
   @include media-breakpoint-up(lg) {
    height: 560px;
   }
  }
 }

}