/* ==========================================================================
   
	AWARDS

========================================================================== */

.award-row {

    justify-content: center;
    margin-top: 50px;

    .bootstyle {
        text-align: center;
        overflow: hidden;
    }

}

.award-year {
    position: relative;
    color: $brand;
    font-size: 42px;
    font-family: $font-secondary;
    text-align: center;
    display: inline-block;
    margin-bottom: 15px;
    line-height: 1.5;
    @include media-breakpoint-up(lg) {
        font-size: 50px;
    }

    &:before,
    &:after { 
        content:'';
        position: absolute;
        height:1px;
        width:9999px;
        background-color:#e1e7f4;
        top: 50%;
    }

    &:before { 
        left: 100%;
        transform:translateX(25px);
    }

    &:after { 
        right:100%;
        transform:translateX(-25px);
    }

}

.award-item {

    text-align: center;
    padding: 0 10px;
    margin-bottom: 30px;

    @include media-breakpoint-up(lg) {

        margin-bottom: 50px;

    }

    @include media-breakpoint-up(xl) {

        padding: 0 30px;
        margin-bottom: 80px;

    }

    .title {
        font-size: 30px;
        font-family: $font-secondary;
        font-weight: $font-semibold;
        margin-bottom: 10px;
        color: $grey-darker;
        
        @include media-breakpoint-up(lg) {
            font-size: 30px;
        }
    }

    p {

        font-size: 15px;
        line-height: 40px;

        @include media-breakpoint-up(lg) {
            font-size: 17px;
            line-height: 1.8;
        }

    }

}