/* ==========================================================================
   
   BLOCK EVENTS

========================================================================== */

.events-wrap {

	position: relative;
	margin-bottom: 30px;

	@include media-breakpoint-up(lg) {

		margin-bottom: 60px;

	}

	.events-count {

		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 100;

		color: $white;
		font-family: $font-secondary;
		font-size: 70px;
		right: calc(5% - 25px);
		display: none;

		@include media-breakpoint-up(md) {
			display:block;
		}

		@include media-breakpoint-up(lg) {
			right: calc(5% - 35px);
		}

		@include media-breakpoint-up(xl) {
			right: calc(5% - 45px);
		}


		.total {

			color: $brand;
			font-size: 40px;

		}

		.current {
			@include media-breakpoint-down(lg) {
				text-shadow: 1px 1px 7px rgba(0, 0, 0, 0.45);
			}
		}

	}

	.events-nav {

		position: absolute;
		right: 20px;
		bottom: 0;
		z-index: 100;

		@include media-breakpoint-down(sm) {

			display: none;
				
		}

		@include media-breakpoint-up(xl) {

			right: 5vw;

		}

		.icon-prev {

			margin-right: 15px;

		}

	}

	.events-slider {

		width: 100%;

		.slick-slide {

			&.showlast {

				opacity: 1!important;

				.event {

					transform: scale(.9) translateX(105%);

				}

			}

		}

		.slick-active {


			.events-item .event {

				transform: scale(1) translateX(0);
				transition: all 0.6s ease-in-out;

				.event-inner {

					&:before {

						opacity: 1;

					}

					.event-all {

						opacity: 1;
						transform: translateY(0);
						transition-delay: 0.3s;

					}

					.event-cat,
					.title h3,
					.location {

						opacity: 1;
						transform: translateY(0);

					}

					.event-cat { transition-delay: 0.3s; }
					.title h3 { transition-delay: 0.5s; }
					.location { transition-delay: 0.7s; }

				}

			}


		}

		.slick-current {

			& + .slick-slide {
				opacity: 0.75!important;
				
				@include media-breakpoint-up(xl) {
					opacity: 1!important;
				}

				.events-item .event {

					transform: scale(.9) translateX(105%);

				}

			}

		}

		.slick-dots {

			right: 20px;
			bottom: 30px;

			width: auto;

			@include media-breakpoint-up(sm) {

				right: 8%;

			}

			@include media-breakpoint-up(md) {

				right: calc(20% + 60px);

			}

			@include media-breakpoint-up(xl) {

				right: calc(50% + 60px);

			}

		}

	}

	.event {

		position: relative;
		width: 100%;
		height: 360px;

		@include media-breakpoint-up(sm) {

			height: 450px;

		}

		@include media-breakpoint-up(md) {

			width: 80%;
			height: 600px;

		}

		@include media-breakpoint-up(xl) {

			width: 50%;
			height: 800px;

		}

		.event-inner {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: rgba(0, 0, 0, 0.3);
				opacity: 0;
				transition: opacity 0.3s ease-in-out;
			}

			.link-target {
			    position: absolute;
			    top: 80px;
			    right: 0;
			    bottom: 0;
			    left: 0;	
			    z-index: 10;			
			}			

			.event-all {

				position: absolute;
				top: 30px;
				right: 0;
				left: 0;
				font-size: 14px;
				color: $white;
				text-transform: uppercase;
				text-align: right;
				min-height: 60px;

				opacity: 0;
				transition: all 0.6s ease-in-out;
				padding: 0 50px;

				@include media-breakpoint-up(sm) {
					padding: 0 55px;
				}

				@include media-breakpoint-up(xxxl) {

					font-size: 18px;
					right: 30px;

				}

				&:before {
					position:absolute;
					right: 10px;
					display: inline-block;
					color: $white;
				}

			}

		}

		.event-content {

			position: absolute;
			left: 0;
			bottom: 0;
			padding: 6% 6% 15%;

			@include media-breakpoint-up(lg) {

				padding: 10%;

			}

			.title h3 {
				
				font-family: $font-secondary;
				font-size: 25px;
				line-height: 28px;
				margin-bottom: 15px;
				color:$light;

				opacity: 0;
				transform: translateY(30px);
				transition: all 0.6s ease-in-out;

				@include media-breakpoint-up(md) {
					
					font-size: 45px;
					line-height: 55px;
					margin-bottom: 35px;

				}

				@include media-breakpoint-up(xxxl) {

					font-size: 60px;
					line-height: 75px;

				}

			}

			.event-cat {

				border: 1px solid $white;
				padding: 6px 28px;
				font-size: 14px;
				margin-bottom: 25px;
				display: inline-block;
				color:$light;
				opacity: 0;
				transform: translateY(30px);
				transition: all 0.6s ease-in-out;

				@include media-breakpoint-up(lg) {

					margin-bottom: 40px;

				}

			}

			.location {

				color: $white;
				text-transform: uppercase;
				font-size: 12px;
				line-height: 22px;
				letter-spacing: 0.2em;
				margin-bottom: 0;

				opacity: 0;
				transform: translateY(30px);
				transition: all 0.6s ease-in-out;

				@include media-breakpoint-up(sm) {

					font-size: 14px;

				}

				@include media-breakpoint-up(xxxl) {

					font-size: 18px;

				}

			}

			a {

				color: $white;

			}

		}

		.event-bg {

			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			width: 100%;
			height: 100%;

		}

	}

}
