.contactform-default {

	&.talk-request-wrapper {
		padding: 0;
		background-color: $white;

		border: 1px solid $border-light;
	}

	header {
		width: 100%;
		background: $brand;
		padding: 25px 20px;

		.title {
			color: $white;
			text-transform: uppercase;
			text-align: center;
			font-size: 16px;
			font-weight: bold;
			letter-spacing: 0.2em;
			margin-bottom: 0;
		}
	}

	.wpcf7-response-output {
		margin-top: -60px;
		margin-bottom: 40px;
	}
	
}

.contact-form-page-title {
	letter-spacing: 0.2em;
	font-size: 38px;
	line-height: 50px;
	margin-bottom: 35px;

	@include media-breakpoint-up(xl) {
		font-size: 45px;
		line-height: 60px;
		margin-bottom: 50px;
	}

}

.page-template-cdv-contact {

	.hero {

		margin-bottom: 50px;

		@include media-breakpoint-up(lg) {
			
			margin-bottom: 85px;
			
		}

	}

	.contact-image {

		margin-top: 10px;
		margin-bottom: 30px;

		@include media-breakpoint-up(lg) {

			margin-top: 50px;
			margin-left: -10vw;
			
		}

	}

}