/* Styling for the Career single page banner */
.career-banner {

    background-color: $light-grey;
    padding:20px 0;
    @include media-breakpoint-up(md) {
        padding: 50px 0;
    }

    .content {
        max-width: 90%;        
    }

    .back-link {
        margin-bottom:30px;
        font-family:$font-secondary;
        font-size:24px;
        color:$black;
        position:relative;
        @extend .d-flex;
        @extend .align-items-center;

        &:before {
            font-family: 'icomoon' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content:'\e900';
            color:$brand;
            font-size:.8em;
            margin-right:20px;
        }

        @include media-breakpoint-up(md) {
            font-size: 30px;
        }
    }

    .title {
        text-transform: uppercase;
        font-size: 30px;
        letter-spacing: 3px;
        line-height: 1.5;
        letter-spacing: 2px;

        @include media-breakpoint-up(md) {
            letter-spacing: 4px;
        }

        @include media-breakpoint-up(lg) {
            font-size: 34px;
        }

        @include media-breakpoint-up(xl) {
            font-size: 42px;
        }

    }

    .locations {

        p {
            color:$dark;
            text-transform: uppercase;
            vertical-align: sub;        
            padding-right: 5px;              
            font-size:20px;
            letter-spacing: 1px;  
            margin-top: 25px;
            display: block;
            margin-bottom: 0;

            @include media-breakpoint-up(mobile-lg) {
                display: inline-block;
                margin-bottom: 15px;
                margin-top: 15px;
            }

            @include media-breakpoint-up(md) {
                margin-top:30px;    
                letter-spacing: 2px;  
            }

            @include media-breakpoint-up(lg) {
                font-size: 24px;
            }
        }

        span {
            font-size: 16px;
            display:inline-block;
            margin-top:10px;
            margin-left: 5px;
            margin-top: 0;
            transform: translateY(2px);

            &:first-of-type {
                margin-left:0;
            }
        }
    }

    .btn-content {
        @extend .d-flex;
        width:100%;
        padding-top: 60px;

        @include media-breakpoint-up(mobile-lg) {
            padding-top: 40px;
        }

        @include media-breakpoint-up(md) {
            padding-top: 60px;
        }

        @include media-breakpoint-up(lg) {
            justify-content: center;
        }

        .btns {
            text-align: center;
            width: 100%;

            @include media-breakpoint-up(md) {
                width: 90%;
            }

            @include media-breakpoint-up(lg) {
                width: 80%;
            }

            @include media-breakpoint-up(xl) {
                width:60%;
            }
        }

        a {
            padding: 24px 0;
            width: 100%;
            max-width: 75%;
            margin-left: auto;
            margin-right: auto;

            @include media-breakpoint-up(md) {
                max-width: 100%;
            }

            span {
                font-size:14px;

                @include media-breakpoint-up(sm) {
                    font-size:16px;
                }
            }

            &.text-link {
                color:$text-color;
                display:block;

                @include media-breakpoint-up(lg) {
                    letter-spacing:1px;
                    text-align:center;
                }
            }
        }
    }
}