/* Styles for the Profile Listing component */

.profile-listing {
    padding:20px 0;
    @include media-breakpoint-up(md) {
        padding:50px;
    }
    .title {
        color:#000;
        text-align:center;
        margin-bottom:50px;
        text-transform:uppercase;
        &.with-subtitle {
            margin-bottom:20px;
        }
    }

    .subtitle {
        text-align:center;
        font-size:18px;
        margin-bottom:30px;
    }

    .profile-item {
        display:block;
        padding:20px;
        text-align:center;
        
        .image {
            display:block;
            margin:auto;
            width:120px;
            height:120px;
            @include border-radius(50%);
            overflow:hidden;

            .image-inner {
                @include background-cover();
                height:100%;
                width:100%;
            }
        }
        .title, p {
            margin-bottom:0;
        }
        .title {
            font-size:18px;
            text-transform:uppercase;
            margin-top:30px;
        }
        p {
            font-size:15px;
            margin-top:20px;
            color:$text-color;
            padding:0 20px;
        }
    }

}