.bulleted-alt {
	padding-bottom: 35px;
	
	.bulleted-alt-header {
		text-align: center;
		padding-top: 50px;
		position: relative;
		padding-bottom: 70px;

		@include media-breakpoint-up(lg) {
			padding-bottom: 95px;
		}

		&:before { 
			content:'';
			position: absolute;
			height:2px;
			width:50px;
			background-color:$brand;
			margin-left: -25px;
		}

		&__hdr {
			text-transform: uppercase;
			letter-spacing:4px;
			color:$grey-darker;
			padding-bottom: 40px;
			padding-top: 40px;
			margin-bottom: 0;
			font-size: 30px;

			@include media-breakpoint-up(sm) {
				font-size:35px;
			}

			@include media-breakpoint-up(md) {
				font-size:42px;
			}
		}

		&__para {
			font-size:15px;
			color:$grey-alt;
			margin-bottom: 0;
			line-height: 2;
    		max-width: 800px;
    		margin-left: auto;
    		margin-right: auto;			
		}
	}

	.inner-listing {
		padding-left: 0;
		margin-bottom: 0;

		&__item {
			text-transform: uppercase;
			letter-spacing:2px;
			position: relative;
			color:$brand;
			display:inline-block;
			width:100%;
			padding-left: 30px;
			padding-right: 30px;
			vertical-align: top;
			font-size:17px;
			margin-bottom: 40px;

			@include media-breakpoint-up(md) {
				width: 32%;
				margin-bottom: 60px;
			}

			@include media-breakpoint-up(lg) {
				width:32%;
				font-size:20px;
			}	

			@include media-breakpoint-up(xl) {
				padding-right: 50px;
				width:33%;
			}	
			
			&:before { 
				content:'';
    			position: absolute;	
    			left:0;
    			top: 8px;
    			width:12px;
    			height:12px;
    			border-radius:50%;
    			background-color:$brand;
			}				

			span {
				color:$grey-darker;
			}
		}
	}
}
