/* ==========================================================================
   
   DISCOVER MASONRY

========================================================================== */


.page-block.discover {

	background: #000;
	padding-top: 40px;
	padding-bottom: 10px;

	@include media-breakpoint-up(lg) {

		padding-top: 80px;
		padding-bottom: 10px;
		
	}

	header.title-button {

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		margin-bottom: 40px;
		padding-left: 0;

		@include media-breakpoint-up(lg) {

			flex-direction: row;
			margin-bottom: 60px;

		}

		.title {

			font-size: 38px;
			text-transform: uppercase;
			letter-spacing: 0.2em;
			color: $white;
			margin-bottom: 0;

			&:before {

				content: '';
				width: 64px;
				height: 2px;
				background: $brand;
				display: block;
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 32px;

			}

			@include media-breakpoint-up(lg) {

				font-size: 45px;

			}

		}

	}

}

.discover-masonry {
	
	display: flex;
	flex-direction: column;
	margin-bottom: 0px;

	@include media-breakpoint-up(lg) {

		flex-direction: row;
		flex-wrap: wrap;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 60px;

	}

	.row {
		width: 100%;
    	margin-left: 0;
    	margin-right: 0;		
	}

	.bootstyle {
		height: 355px;

		@include media-breakpoint-up(lg) {
			height: 515px;
		}

		&:nth-of-type(3n+1) {
			@include media-breakpoint-up(lg) {
				padding-left:0;
			}

			.masonry-item {
				left: 0;
				right:0;
				position: absolute;

				@include media-breakpoint-up(lg) {
					right: -75px;  
				}
			}

			.masonry-inner {
				.title-preview {
					width:auto;

					@include media-breakpoint-up(lg) {
						right: 150px;
					}

					@include media-breakpoint-up(xxl) {
						right: 100px;
					}
				}

				.masonry-content {
    				width: 100%;
    				max-width: none;
    				padding: 0;					

					@include media-breakpoint-up(lg) {
	    				padding-left: 5%;
	    				max-width: 57.5%;					
					}

					@include media-breakpoint-up(xl) {
	    				padding-left: 10%;
	    				max-width: 60%;					
					}

    				@include media-breakpoint-up(xxl) {
						max-width: 65%;
    				}
				}
			}
		}

		&:nth-of-type(3n+2) {
    		position: relative;
    		z-index: 5;

    		@include media-breakpoint-up(lg) {
				transform: skewX(-30deg);
    		}

    		.masonry-inner {
    			width:auto;

    			@include media-breakpoint-up(lg) {
	    			left:-200px;
	    			right:-200px;
    			}

				.masonry-content {    			
				    margin-left: auto;
				    margin-right: auto;

				    @include media-breakpoint-up(lg) {
	    				max-width: 35%;
	    				transform: translateX(20px);				       
				    }

				    @include media-breakpoint-up(xl) {
    					max-width: 35%;
    					transform: translateX(0);				       
				    } 		

				    @include media-breakpoint-up(xxl) {
				    	max-width: 45%;
				    }
				}	
    		}

    		.masonry-inner,
    		.masonry-bg {

    			@include media-breakpoint-up(lg) {
    				transform: skewX(30deg);
    			}
    		}

    		.masonry-item {
			    position: absolute;
			    top: 0;
			    bottom: 0;
			    left:0;
			    right:0;

			    @include media-breakpoint-up(lg) {
				    box-shadow: 0px 0px 0px 15px black;			
				    left: -50px; 
				    right: -25px;
			    }

			    @include media-breakpoint-up(xl) {
			    	right: -50px;
			    }   
    		}
		}

		&:nth-of-type(3n+0) {
			padding-right:0;

			.masonry-item {
				position: absolute;
				right: 0;
				left: 0;

				@include media-breakpoint-up(lg) {
    				left: -110px;
				}

				@include media-breakpoint-up(xl) {
					left: -85px;  
				}
			}	

			.masonry-inner {
				.title-preview {
					left: 200px;
					width:auto;
				}

				.masonry-content {

				    @include media-breakpoint-up(md) {
						max-width: 50%;
	    				padding-right: 2.5%;				    
	    				margin-left: auto;				
				    }

				    @include media-breakpoint-up(xl) {
					    padding-right: 5%;
				    }

				    @include media-breakpoint-up(xxl) {
						max-width: 60%;
	    				padding-right: 10%;	
				    }
				}				
			}					
		}		
	}

	// Animate
	@include media-breakpoint-up(xl) {

		.masonry-item {

			transition: transform 0.6s ease-in-out;
			width:auto;

		}
		
	}

	.bootstyle {
		padding-left: 7.5px;
		padding-right: 7.5px;

		&:nth-of-type(odd) {
			.masonry-item {
				@include media-breakpoint-down(md) {
					left: -100px!important;
				}

				.masonry-content {
					@include media-breakpoint-down(sm) {
						transform: skew(30deg) translateX(40px);
					}
				}
			}
		}

		&:nth-of-type(even) {
			.masonry-item {
				@include media-breakpoint-down(md) {
					right: -100px!important;
				}

				.masonry-content {
					@include media-breakpoint-down(md) {
						padding-right: 10%;
					}

					@include media-breakpoint-down(sm) {
						max-width: 50%!important;
						padding-right: 0;
						transform: skew(30deg) translateX(-15px);
					}
				}
			}
		}			
	}

}

.masonry-item {
	position: relative;
	flex: 1 0 auto;
	padding-left: 0;
	padding-right: 0;
	top:0;
	bottom:0;
	overflow: hidden;

	@media (max-width: 576px) {
		transform: skewX(0deg)!important;	
	}

	@include media-breakpoint-down(md) {
		transform: skewX(-30deg);
	}


	@include media-breakpoint-up(lg) {
	    margin-bottom: 15px;
	    margin-top: 15px;
		height: 500px;

	}

	@include media-breakpoint-up(xl) {

		width: calc(100% / 3);

	}

	@include media-breakpoint-up(xxl) {

		width: 30%;

	}

	&:last-child {

		margin-bottom: 0;

	}

	&:nth-last-child(-n+3) {

		@include media-breakpoint-up(lg) {

			margin-bottom: 0;

		}

	}


	@include media-breakpoint-up(xl) {

		&:hover { 

			.masonry-inner {

				background-color: rgba(0, 0, 0, 0.8);

				.title-preview {

					transform: translateY(-50%) scale(0.7);
					opacity: 0;

				}
				
			}

			.masonry-content {

				visibility: visible;
				opacity: 1;

			}

		}
		
	}

	.masonry-inner {

		position: absolute;
		top: 0; right: 0; bottom: 0; left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.6);
		z-index: 1;

		transition: all 0.5s ease-in-out 0.1s;

		@include media-breakpoint-down(md) {
     		box-shadow: inset 0px 0px 0px 5px black;	
		}


		@include media-breakpoint-up(xl) {
			
			background-color: rgba(0, 0, 0, 0.2);

		}

		.title {
			text-transform: uppercase;
			line-height: 27px;
			margin-bottom: 18px;
			color: $white;
    		height: 55px;
    		display: flex;
    		align-items: flex-end;  
    		font-size: 17px;
    		letter-spacing: 1px;  		

    		@include media-breakpoint-up(md) {
				letter-spacing: 0.2em;
	    		font-size: 18px;			
    		}

    		@include media-breakpoint-up(lg) {
    			max-width: 100px;
    		}

			@include media-breakpoint-up(xxl) {
				font-size: 20px;
			}

			@media (max-width: 576px) {
				margin-bottom: 0px;
			}

		}

		.title-preview {

		    position: absolute;
		    top: 50%;
		    right: 0;
		    left: 0;

		    margin: 0 auto;
		    width: 100%;
		    max-width: 50%;

		    transition: all 0.6s ease-in-out;
		    text-align: center;
		    margin-bottom: 0;
		    display: none;

		    @include media-breakpoint-up(xl) {

			    display: block;
			    transform: translateY(-50%) scale(1);

		    }
 
		}

	}

	.masonry-content {
		position: relative;
		color: white;
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;


		@include media-breakpoint-up(lg) {

			transform: translateY(0);
			visibility: visible;
			opacity: 1;

		}

		@include media-breakpoint-up(xl) {

			opacity: 0;
			visibility: hidden;
			transition: all 0.4s ease-in-out;

		}

		@include media-breakpoint-down(md) {
		    max-width: 50%!important;
		    margin-left: auto;
		    margin-right: auto;		
		    transform: skew(30deg) translateX(15px);	
		}

		p {

			font-size: 15px;
			line-height: 28px;
    		height: 115px;
    		display: flex;
    		align-items: center;			

		}

	}
	
	.masonry-bg {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: top center;
		position: absolute;
		top:0;
	    bottom: 0;
	    right:-100px;
	    left:-100px;

	    @include media-breakpoint-up(lg) {
		    right: -150px;
		    left: -150px;
	    }

	    @include media-breakpoint-down(md) {
    		transform: skewX(30deg);	    
	    }

		@media (max-width: 576px) {
			transform: skew(0deg) translateX(0px)!important;
	    	right:0px;
	    	left:0px;			
		}	    
	}

}


.masonry-item.masonry-item.masonry-item.masonry-item.masonry-item {
	@media (max-width: 576px) {
		left:0px!important;
		right:0px!important;
	}

	.masonry-content.masonry-content {
		@media (max-width: 576px) {
			transform: skew(0deg) translateX(0px)!important;
			max-width: 75%!important;
		}
	}
}
	