.image-text {
	padding-top: 80px;
	padding-bottom: 80px;

	&.white {
		background-color:white;
	}

	&.light-grey {
		background-color:$light-grey;
	}

	&.dark-grey {
		background-color:$grey-darker;	

		.text-side {
			&__para {
				color:$light;
			}
		}
	}

	&.brand {
		background-color:$brand;
		p {
			color:#fff;
		}
	}

	.image-side {
		width:100%;
		@include background-cover();
		position: relative;
		height: 415px;

		@include media-breakpoint-up(md) {
			height:615px;
		}

		.inner-box {
			position: absolute;
			bottom:0;
			right:0;
    		width: 100%;
    		background-color:$brand;			
    		text-align: center;
    		max-width: 300px;
    		padding: 30px;

    		@include media-breakpoint-up(md) {
    			padding: 30px 20px;
    		}

    		@include media-breakpoint-up(xl) {
    			max-width: 400px;
    			padding: 40px 30px;
    		}
	
			&__hdr {
				color:$light;
				margin-bottom: 0;
				letter-spacing: 5px;
				font-size:50px;

				@include media-breakpoint-up(md) {
					font-size:60px;
				}

				@include media-breakpoint-up(xl) {
					font-size:70px;
					letter-spacing: 10px;
				}
			}
			&__para {
				color:$light;
				margin-bottom: 0;
				padding-top: 15px;
				font-size: 15px;
			}
		}
	}

	.text-side {
		width: 90%;

		@include media-breakpoint-up(lg) {
			width: 80%;
		}

		&__hdr {
			position: relative;
			text-transform: uppercase;
			color:$brand;
			letter-spacing: 4px;
			margin-bottom: 0;
			padding-bottom: 25px;
			line-height: 1.5;
			max-width: 75%;		
			font-size:26px;	

			@include media-breakpoint-up(lg) {
				padding-bottom: 45px;
				font-size: 32px;
			}

			&:before { 
				content:'';
				position: absolute;
				top: -40px;
				width:55px;
				height:2px;
				background-color:$brand;
			}

			&.remove-line {
				&:before { 
					display:none;
				}
			}

			&.brand {
				color:$brand;
			}

			&.white {
				color:white;
			}

			&.black {
				color:black;
			}
		}
		&__para {
			margin-bottom: 0;
			line-height: 1.7;
			font-size: 16px;

			@include media-breakpoint-up(lg) {
				font-size: 18px;
			}

			@include media-breakpoint-up(xl) {
				font-size: 22px;
			}

		}
	}

	.order-1 {
		.text-side {
			padding-bottom: 80px;

			@include media-breakpoint-up(md) {
				padding-bottom: 0;
			}			
		}
	}

	.order-2 {
		.text-side {
			padding-top: 90px;	
			
			@include media-breakpoint-up(md) {
				padding-top: 0;
			}		
		}
	}
}
