/* Styles for the Locations component */

.block-locations {

    background:$grey-darker;
    padding: 60px 0 30px;

    .section-title {
        color:#fff;
        text-transform: uppercase;
        text-align:center;
        display:block;
        margin-bottom:20px;
        letter-spacing: 4px;
        font-size: 26px;
        line-height: 1.5;

        @include media-breakpoint-up(lg) {
            font-size: 30px;
        }

        span {
            color:$brand;
        }
    }

    p {
        color:#fff;
        text-align:center;
        display:block;
        width:100%;
        margin:auto;
        letter-spacing: 1px; 
        padding-top: 15px;   
        padding-bottom: 50px;    
        font-size: 16px;

        @include media-breakpoint-up(lg) {
            font-size: 20px;
        }
    }

    .divider-line {
        background-color:$light;
        width:100%;
        height:1px;
        background-color: rgba(255, 255, 255, 0.06);
    }

    .locations-carousel {
        margin-top:20px;
        padding-top:30px;
        padding-bottom:40px;
        text-align:center;
        width:100%;
        position:relative;

        .location-name {
            text-transform: uppercase;
            color:#fff;
            text-align:center;
            display:block;
            letter-spacing: 3px;
            transition:all .5s ease-in-out;
            margin:0;
            transform: scale(.7);
            padding:10px 15px;

            @include media-breakpoint-up(xl) {
                padding:20px 30px;
            }
        }

        .slick-current {
            .location-name {
                color:$brand;
                transform:scale(1.1);

                @include media-breakpoint-up(sm) {
                    transform:scale(1.3);
                }
            }
        }

        .slick-arrow {
            position:absolute;
            top:50%;
            transform:translateY(-50%);
            display:block;
            font-size:30px;
            background-color:$grey-darker;
            z-index:5;
            margin-top: -5px;
            height:35px;
            width:35px;

            @include media-breakpoint-up(xs) {
                width:50px;
            }

            span {
                &:before {
                    color:#fff;
                    transition:color .5s ease-in-out;
                }
            }
            &:before {
                display:none;
                content:'';
            }
            &.slick-prev {
                left: -15px;

                @include media-breakpoint-up(md) {
                    left:0;
                }

                @include media-breakpoint-up(sm) {
                    left:-5px;
                }
            }
            &.slick-next {
                right: -15px;
                
                @include media-breakpoint-up(md) {
                    right:0;
                }

                @include media-breakpoint-up(sm) {
                    right:-5px;
                }
            }
            &:hover {
                span {
                    &:before {
                        color:$brand;
                    }
                }
            }
        }
    }

}