/* ==========================================================================
   
   FONTS

========================================================================== */

// Avenir
@font-face {
    font-family: 'Avenir';
    src: url('../fonts/Avenir-Medium.woff2') format('woff2'),
        url('../fonts/Avenir-Medium.woff') format('woff'),
        url('../fonts/Avenir-Medium.ttf') format('truetype'),
        url('../fonts/Avenir-Medium.svg#Avenir-Medium') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir';
    src: url('../fonts/Avenir-Heavy.woff2') format('woff2'),
        url('../fonts/Avenir-Heavy.woff') format('woff'),
        url('../fonts/Avenir-Heavy.ttf') format('truetype'),
        url('../fonts/Avenir-Heavy.svg#Avenir-Heavy') format('svg');
    font-weight: 900;
    font-style: normal;
}

// Baskerville
@font-face {
    font-family: 'Baskerville';
    src: url('../fonts/Baskerville-Italic.woff2') format('woff2'),
        url('../fonts/Baskerville-Italic.woff') format('woff'),
        url('../fonts/Baskerville-Italic.ttf') format('truetype'),
        url('../fonts/Baskerville-Italic.svg#Baskerville-Italic') format('svg');
    font-weight: normal;
    font-style: normal;
}
