.featured-stats {
	background-color:black;
	padding-top: 70px;
	padding-bottom: 30px;

	@include media-breakpoint-up(sm) {
		padding-top: 120px;
		padding-bottom: 60px;
	}

	.featured-stats-textarea {
		text-align: center;
		padding-bottom: 60px;

		@include media-breakpoint-up(md) {
			padding-bottom: 80px;
		}

		&__hdr {
			position: relative;
			color:$light;
    		letter-spacing: 8px;	
    		margin-bottom: 0;
    		padding-bottom: 20px;   
    		padding-top: 30px;
    		font-size: 50px;

    		@include media-breakpoint-up(sm) {
    			font-size: 56px;
    		} 	

    		@include media-breakpoint-up(lg) {
    			font-size: 62px;
    		}

    		&:before { 
    			content:'';
    			position: absolute;
    			top:0;
    			height:1px;
    			width:50px;
    			background-color:$brand;
    			left:50%;
    			margin-left: -25px;
    		}			
		}
		&__para {
			color:$light;
		    max-width: 500px;
		    margin-left: auto;
		    margin-right: auto;
		    line-height: 2;	
		    padding-left: 15px;
		    padding-right: 15px;
		    font-size: 16px;

		    @include media-breakpoint-up(sm) {
		    	font-size: 18px;
		    }

		    @include media-breakpoint-up(lg) {
		    	font-size: 20px;
		    }		
		}
	}
}
