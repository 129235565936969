.client-login-header {

	background: $white;
	padding: 35px 15px;

	text-align: center;
	margin-top: -50px;
	margin-bottom: 100px;
	border: 1px solid $border-light;

	position: relative;
	@include z-index(aboveHero);

	@include media-breakpoint-up(md) {

		padding: 65px 30px;
		margin-top: -150px;

	}

	.title {

		font-size: 38px;
		line-height: 50px;
		text-transform: uppercase;
		letter-spacing: 0.2em;
		margin-bottom: 30px;

		@include media-breakpoint-up(lg) {

			font-size: 45px;
			line-height: 60px;
			
		}

	}

	.client-login-inner {
		
		max-width: 980px;
		margin-left: auto;
		margin-right: auto;

	}

}

.client-logos {

	align-items: center;
	max-width: 1200px;
	margin: 0 auto;

	&:hover {

		.logo {
			
			@include media-breakpoint-up(md) {

				opacity: 0.2;
				
			}

		}

	}

	.logo {

		text-align: center;
		margin-bottom: 80px;

		@include media-breakpoint-up(md) {

			opacity: 0.2;
			transition: opacity 0.3s ease-in-out;
			
		}

		&:hover {
			
			@include media-breakpoint-up(md) {

				opacity: 1;
				
			}

		}

		img {

			width: 100%;
			height: auto;

			max-width: 205px;
			max-height: 150px;
			
		}

	}

}