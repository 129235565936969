.appointment-page-wrapper {
    &.step-1 {
        display: none;
        &.active {
            display: block;
        }
        .appointment-option {
            height: calc(100vh - 26px);
            @include media-breakpoint-up(lg) {
                height: calc(100vh - 91px);
            }
            display: flex;
            align-items: center;
            text-align: center;
            position: relative;
            h3 {
                font-size: 18px;
                margin: 0 auto 15px;
                @include media-breakpoint-up(md) {
                    font-size: 28px;
                    margin: 0 auto 50px;
                }
                letter-spacing: 0.1em;
                line-height: 1.5;
                color: $black;
                width: 90%;
                max-width: 630px;
            }
            .image {
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                position: absolute;
                background-size: cover;
                background-position: 100% 0%;
                @include media-breakpoint-up(lg) {
                    background-position: center right;
                }
                pointer-events: none;
            }
            .inner {
                position: relative;
                z-index: 1;
                margin: 0 auto;
            }
            &.consultation {
                background: $grey-alt;
                .image {
                    opacity: 0.1;
                }
                .inner {
                    h3 {
                        color: white;
                    }
                }
                a.btn {
                    background-color: white;
                    span {
                        color: $brand;
                    }
                }
            }
        }
    }
    &.step-2 {
        display: none;
        &.active {
            display: block;
        }
        padding: 75px 0;
        text-align: center;
        h4 {
            font-size: 18px;
            margin-bottom: 15px;
        }
        h4 {
            text-transform: uppercase;
            padding-left: 20px;
            padding-right: 20px;
        }

        .btn-wrapper {
            a {
                display: block;
                margin: 0 8px;
                @include media-breakpoint-up(lg) {
                    display: inline-block
                }
            }
        }

    }
}

.page-template-cdv-appointment {
    footer.primary {
        display: none;
    }
}
.page-template-cdv-thankyou-appointment,
.page-template-cdv-arrangeconsultation{
    footer.primary{
        .consultation{
            display: none;
        }
    }
}
