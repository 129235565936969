.page-template-cdv-nonmember {
    footer.primary {
        .consultation {
            display: none;
        }
    }
    .checkbox-wrap {
        p {
            line-height: 1.7;
            font-size: 14px;
        }
    }
    .description{
        width: 400px;
        margin: 0 auto;
        text-align: center;
    }
}