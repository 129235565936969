.container-top-page {
  .image-text { padding-top: 20px; }
}

.text_subtitle {
  font-family: Baskerville,serif;
  font-style: oblique;
  color: #f01a3a;
  display: inline-block;
  font-size: 24px;
  font-size: 32px;
}
@media(min-width: 768px) {
  .text_subtitle { font-size: 28px; font-size: 40px; }
}
@media(min-width: 1200px) {
  .text_subtitle { font-size: 32px; font-size: 60px; }
}

.text-side__hdr + .text_subtitle { margin-top: -25px; }
@media(min-width: 768px) {
  .text_subtitle + p { margin-top: 15px; }
}
@media(min-width: 992px) {
  .text-side__hdr + .text_subtitle { margin-top: -35px; }
}
@media(min-width: 1200px) {
  .page-id-8365 {
    .image-text {
      .text-side__hdr { font-size: 50px; }
    }
  }
}
.page-id-8365 {
  .image-text {
    .text-side__para {
      font-size: 15px;
      line-height: 25px;
      text-transform: uppercase;
    }
  }
}

.container-tabs {
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #f8f8f8;
  .wrapper-tab-content {
    width: 100%;
    background-color: #FFF;
    padding: 30px;
    position: relative;
  }
  .info_content {
    width: 100%;
    > .container-fluid {
      display: none;
    }
    .container-fluid { padding: 0; }
    &.is--open {
      > .container-fluid {
        display: block;
        -webkit-animation: fadeInEffect 1s ease 1 forwards;
        animation: fadeInEffect 1s ease 1 forwards;
      }
    }
  }
}
@media(min-width: 768px) {
  .container-tabs {
    .wrapper-tab-content {
      padding: 60px;
    }
  }
}

.navtabs {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
  li {
    margin-right: 15px;
    a {
      font-family: Baskerville,serif;
      font-style: oblique;
      color: #000;
      position: relative;
      font-size: 24px;
      line-height: 1;
      display: block;
      padding-bottom: 12px;
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 4px;
        background-color: #f01a3a;
        transform: scaleX(0);
        transform-origin: right center;
        transition: transform 300ms ease;
      }
      &:hover,
      &.selected {
        color: #f01a3a;
      }
      &.selected {
        &::after {
          transform: scaleX(1);
          transform-origin: left center;
        }
      }
    }
    &:last-child { margin-right: 0; }
  }
}
@media(min-width: 768px) {
  .navtabs {
    li {
      a { font-size: 32px; }
      margin-right: 30px;
    }
  }
}

.text-card {
  h2 {
    font-size: 18px;
    line-height: 1.2;
    text-transform: uppercase;
    color: #000;
    letter-spacing: 1px;
  }
  p {
    font-size: 15px;
    margin-top: 20px;
    color: #6e6e6e;
    line-height: 1.7;
  }
  .image-side {
    width: 100%;
    padding-bottom: 65%;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
    &.has-btn {
      cursor: pointer;
      display: block;
      .learn-more { font-size: 1rem; }
      &:hover {
        .learn-more {
          .learn-more-text {
            -webkit-transform: translateX(0);
            -o-transform: translateX(0);
            transform: translateX(0);
          }
        }
      }
    }
  }
  .video-side {
    width: 100%;
    padding-bottom: 56.25%;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
    a {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        .v-play-icon {
          transform: scale(1.2);
        }
      }
    }
  }
}
@media(min-width: 768px) {
  .text-card {
    h2 { font-size: 20px; }
  }
}

/*Video*/
.v-play-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #f01a3a;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 300ms ease;
  &::before {
    content: '';
    width: 18px;
    height: 21px;
    display: block;
    background: url("../images/play.svg") no-repeat center / 100% auto;
    margin-left: 5px;
  }
}
@media(min-width: 768px) {
  .v-play-icon {
    width: 80px;
    height: 80px;
    &::before {
      content: '';
      width: 24px;
      height: 28px;
    }
  }
}
.video-container-embed {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
}

.text-col {
  .wrapper-text-col {
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    .icon-item {
      margin-bottom: 20px;
      img {
        height: 46px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    h3 {
      font-size: 18px;
      line-height: 1.2;
      text-transform: uppercase;
      color: #000;
      letter-spacing: 1px;
      margin-bottom: 20px;
    }
  }
}
@media(min-width: 768px) {
  .text-col {
    border-left: 1px solid #DCDCDC;
    &:first-child { border: none; }
    .wrapper-text-col {
      .icon-item {
        img { height: 60px; }
      }
      h3 { font-size: 20px; }
    }
  }
}
@media(min-width: 1440px) {
  .text-col {
    .wrapper-text-col {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
}

.js-carousel-cards {
  .slick-track {
    display: flex;
    flex-wrap: nowrap;
  }
  .slick-dots {
    position: relative;
    bottom: auto;
  }
}
@media(min-width: 768px) {
  .js-carousel-cards {
    .slick-dots { margin-top: 20px; }
  }
}
.nav-arrows {
  .arrow-btn {
    width: 35px;
    height: 76px;
    background-color: #f01a3a;
    text-align: center;
    &::before { display: none; }
    &::after {
      content: "\e901";
      font-family: icomoon!important;
      speak: none;
      font-style: normal;
      font-weight: 400;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      display: inline-block;
      color: #FFF;
      font-size: 9px;
      top: 50%;
      position: relative;
      margin-top: -6px;
    }
    &.slick-next {
      right: 0;
      &::after {
        margin-left: 2px;
        transform: rotate(-90deg);
      }
    }
    &.slick-prev {
      left: 0;
      &::after {
        margin-right: 2px;
        transform: rotate(90deg);
      }
    }
    &.slick-disabled { background-color: #DCDCDC; }
  }
}

/* Slider */
.slick-slider {
  position: relative;

  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;

  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;

  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-dots {
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  li {
    position: relative;
    display: inline-block;
    width: 14px;
    height: 14px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    border: none;
    button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 14px;
      height: 14px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: #DCDCDC;
      transform: none;
      border-radius: 20px;
      max-width: none;
      &:active { transform: none; }
    }
    &.slick-active {
      button { background-color: #f01a3a; }
    }
    &:only-child { display: none; }
  }
}


/*Grid*/
.featured_cards {
  background-color: #000;
  padding: 80px 0;
  .container-fluid { max-width: 1340px; }
  .grid-ftcards {
    [class*=" col-"],
    [class^=col-] { padding: 0 10px; }
  }
}

.ft_card {
  width: 100%;
  margin-top: 20px;
  padding-bottom: 122.5%;
  position: relative;
  overflow: hidden;
  background-color: #000;
  .background-image {
    background-size: cover;
    background-position: 50%;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: .5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    -webkit-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
  }
  .lbl-title {
    text-transform: uppercase;
    margin: 0;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 20px;
    letter-spacing: 1px;
    color: #FFF;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .learn-more { font-size: 1rem; }
  &:hover {
    .background-image {
      -webkit-transform: scale(1.05);
      -o-transform: scale(1.05);
      transform: scale(1.05);
    }
    .learn-more {
      .learn-more-text {
        -webkit-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
      }
    }
  }
}
@media(min-width: 768px) {
  .ft_card {
    margin-top: 0;
    + .ft_card { margin-top: 20px; }
    &.sz1 { /*padding-bottom: 89.9%;*/ padding-bottom: 0; height: 100%; }
    &.sz2 { padding-bottom: 100%; }
    &.sz3 { padding-bottom: 78.6%; }
  }
}
@media(min-width: 992px) {
  .ft_card {
    .learn-more {
      height: 46px;
      .learn-more-text { padding-right: 0; }
      .icon-plus {
        width: 46px;
        &::before { font-size: 16px; }
      }
    }
  }
}
@media(min-width: 1200px) {
  .ft_card {
    &.sz1 {
      .lbl-title {
        > span {
          margin: 0 auto;
          width: 50%;
        }
      }
    }
  }
  .featured_cards {
    .grid-ftcards {
      .col-xl-3 {
        -ms-flex: 0 0 28%;
        flex: 0 0 28%;
        max-width: 28%;
      }
      .col-xl-6 {
        -ms-flex: 0 0 44%;
        flex: 0 0 44%;
        max-width: 44%;
      }
    }
  }
}

.steps-works {
  .step-item {
    display: flex;
    padding-bottom: 10px;
    .step-icon {
      width: 70px;
      min-width: 70px;
      margin-right: 30px;
      background: url("../images/arrow-steps.svg") no-repeat top 100px center, url("../images/arrow-steps.svg") no-repeat top 116px center, url("../images/arrow-steps.svg") no-repeat top 132px center;
      background-size: 14px, 14px, 14px;
      .st-circle {
        width: 70px;
        height: 70px;
        border: 2px solid #F01A3A;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: flex-start;
        img {
          max-width: 34px;
          max-height: 34px;
        }
      }
    }
    .step-info {
      h3 {
        font-size: 18px;
        line-height: 1.2;
        text-transform: uppercase;
        color: #000;
        letter-spacing: 1px;
        margin-bottom: 20px;
      }
    }
    &:last-child {
      padding-bottom: 0;
      .step-icon { background: none; }
    }
  }
}
@media(max-width: 991px) {
  .profile-listing {
    &.howitworks {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
@media(min-width: 768px) {
  .steps-works {
    width: 100%;
    margin: 0 auto;
    .step-item {
      justify-content: flex-end;
      .step-icon {
        margin: 0 30px;
      }
      .step-info {
        width: 280px;
        min-width: 280px;
        h3 {
          font-size: 20px;
          margin-top: 25px;
        }
      }
      &.odd-step {
        justify-content: flex-start;
        .step-icon { order: 2; }
        .step-info { order: 1; }
      }
    }
  }
}
@media(min-width: 992px) {
  .steps-works {
    width: 830px;
    .step-item {
      .step-icon {
        margin: 0 60px;
      }
      .step-info {
        width: 320px;
        min-width: 320px;
      }
    }
  }
}

/*Animations*/
.animate.anim-fadein {
  opacity: 0;
  transition: opacity 800ms linear;
}
.animate.anim-fadein.animated {
  opacity: 1;
}
.animate.anim-fadein_up {
  opacity: 0;
  transform: translate(0, 30px);
  transition: opacity 400ms linear, transform 800ms ease;
}
.animate.anim-fadein_up.animated {
  opacity: 1;
  transform: translate(0, 0);
}
.animate.delay50 {
  transition-delay: 500ms;
}

@keyframes fadeInEffect{
  0%{opacity:0}
  to{opacity:1}
}
