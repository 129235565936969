/* Styling for the Career page Application form */
#career-application {

    background-color: $light-grey;
    padding:20px 0;
    @include media-breakpoint-up(md) {
        padding: 50px 0;
    }

    .section-title {
        @include media-breakpoint-up(md) {
            font-size:45px;
        }
        text-transform: uppercase;
        letter-spacing: 4px;
    }

    .title-supporting {
        margin-top:20px;
        @include media-breakpoint-up(md) {
            max-width:50%;
        }
    }

    .contains-content {
        @include media-breakpoint-up(md) {
            &:first-child {
                padding-right:30px;
            }
            &:last-child {
                padding-left:30px;
            }
        }
    }

    .link-error, .cv-error {
        display:none;
        color:$red;
        &.display-error {
            display:block!important;
        }
    }

    .content-block {
        background:#fff;
        border:1px solid $form-border;
        border-radius:5px;
        padding:20px;
        margin:10px 0;
        display:block;
        @include media-breakpoint-up(xl) {
            margin:20px 0 50px;
            padding: 50px;
        }

        &.centralise {
            text-align:center;
        }

        .block-title, p {
            margin-bottom:30px;
        }

        .block-title, .style-as-title {
            text-transform: uppercase;
            color:$brand;
            font-size:16px;
            word-break: break-word;
            @include media-breakpoint-up(xl) {
                font-size:18px;
            }
        }

        &.main-application {
            margin-bottom: 0;

            .btn {
                text-align:center;
            }

            .row {
                margin-bottom:0;
            }

            .notify-uploads {
                position:relative;
                padding-bottom:30px;
                padding-left: 20px;
                padding-right: 20px;
                @include media-breakpoint-up(lg) {
                    padding-bottom:0;
                    padding-left: 0;
                    padding-right: 0;
                }
                > .col-12 {
                    text-align:center;

                    .form-group {
                        width:100%;
                    }
                    label {
                        width:100%;
                        font-size:16px;
                        padding-left:10px;
                        padding-right:10px;
                        margin:0 auto;
                        display:block;
                        max-width:90%;
                        @include media-breakpoint-up(sm) {
                            font-size:12px;
                            max-width:100%;
                        }
                        @include media-breakpoint-up(xl) {
                            font-size:16px;
                        }
                    }
                    p {
                        display:none;
                        margin:0;
                    }

                }
            }

            .upload-cv {
                position:static;
            }

            .custom-file {
                margin: 20px auto;
                width: auto;
                position:relative;

              .file-notice {
                position:absolute;
                top:15px;
                right:0;
                color:$brand;
              }

                .btn {
                    margin: 0;
                    font-size: 11px;
                    @include media-breakpoint-up(lg) {
                        font-size: 16px;
                    }
                }
                input[type="file"] {
                    display: none;
                }

                .wpcf7-not-valid-tip {
                    position: absolute;
                    bottom: -110px;
                    width: 200px;
                    left: 0;
                    right: 0;
                }

              .filename {
                display: none;
                align-items: center;
                justify-content: center;
                position:relative;
                z-index:50;
                .remove {
                  margin-left: 10px;
                  background: $danger;
                  color: #fff;
                  width: 20px;
                  height: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 50%;
                  cursor: pointer;
                }
                &.active {
                  display: flex;
                }
              }
            }

            .notify-cv-link {
                label {
                    text-transform: none!important;
                }
            }

            .add-cv-links {
                display:none;

                input {
                    margin-top:20px;
                    &:first-of-type {
                        margin-top:0;
                    }
                }

                .add-another {
                    color:$text-color;
                }
            }

            .custom-checkbox {
                margin-bottom:20px;
                @include media-breakpoint-up(lg) {
                    margin:20px 0;
                }
                &.box-left {
                    input[type="checkbox"], input[type="radio"] {
                        display:none;
                        & + label {

                            position:relative;
                            display:flex;
                            align-items: center;
                            text-transform: uppercase;

                            &:before {
                                content: '';
                                width:20px;
                                height:20px;
                                border:1px solid $form-border;
                                background:#fff;
                                @extend .d-flex;
                                @extend .align-items-center;
                                @extend .justify-content-center;
                                padding:0;
                                margin-right:30px;
                            }

                            a {
                                color:$brand;
                                text-decoration: underline;
                            }
                        }

                        &:checked + label {
                            &:before {
                                content: '✓';
                                color:$brand;
                                font-size:16px;
                            }
                        }
                    }
                }

                &.box-right {
                    input[type="checkbox"], input[type="radio"] {
                        display:none;
                        & + label {

                            position:relative;
                            padding:0 40px;
                            display:block;
                            text-align:center;

                            &:after {
                                content: '';
                                position:absolute;
                                top:5px;
                                right: 0;
                                width:20px;
                                height:20px;
                                border:1px solid $form-border;
                                background:#fff;
                                @extend .d-flex;
                                @extend .align-items-center;
                                @extend .justify-content-center;
                                padding:0;
                            }

                            a {
                                color:$brand;
                                text-decoration: underline;
                            }
                        }

                        &:checked + label {
                            &:after {
                                content: '✓';
                                color:$brand;
                                font-size:16px;
                            }
                        }
                    }
                }
            }

            .notice {
                border:1px solid $form-border;
                border-radius:5px;
                padding:20px;
                margin-bottom:20px;
                @include media-breakpoint-up(lg) {
                    margin-bottom:0;
                }
                p {
                    position:relative;
                    margin:0!important;
                    padding-left:40px;

                    a {
                        color:inherit;
                        text-decoration: underline;
                        font-weight:$font-bold;
                    }

                    &:first-child {
                        &:before {
                            position:absolute;
                            top:0;
                            left:0;
                            content:'i';
                            color:$brand;
                            border:2px solid $brand;
                            border-radius: 50%;
                            width:20px;
                            height:20px;
                            display:flex;
                            align-items:center;
                            justify-content: center;
                        }
                    }
                }
            }

            .row {
                @include media-breakpoint-up(lg) {
                    margin-bottom:20px;
                }
            }

            button {
                margin: 0 auto;
                display:block;
            }
        }

        .standard-checkbox {

            position:relative;
            display:flex;

            label {
                width:100%;
                display:block;
                padding-left:10px;
            }
        }

        .form-group {
            label, input {
                width:100%;
                text-align:left;
            }
            label {
                margin-bottom:20px;
                font-size:18px;
            }
            input[type="text"], input[type="email"] {
                border:none;
                border-bottom:1px solid $form-border;
                padding:0;
                padding-bottom:15px;
                &::placeholder {
                    color:$placeholder;
                }
            }
        }

        &.sub-content {
            .custom-checkbox {
                input[type="checkbox"], input[type="radio"] {
                    display:none;
                    & + label {

                        position:relative;
                        display:flex;
                        align-items: center;
                        text-transform: uppercase;

                        &:before {
                            content: '';
                            width:20px;
                            height:20px;
                            border:1px solid $form-border;
                            background:#fff;
                            @extend .d-flex;
                            @extend .align-items-center;
                            @extend .justify-content-center;
                            padding:0;
                            margin-right:30px;
                        }

                        a {
                            color:$brand;
                            text-decoration: underline;
                        }
                    }

                    &:checked + label {
                        &:before {
                            content: '✓';
                            color:$brand;
                            font-size:16px;
                        }
                    }
                }
                input[type="radio"] {
                    & + label {
                        &:before {
                            border-radius:50%;
                        }
                    }
                }
            }
        }
    }

    .sent-alert {
        padding:40px 20px;
        text-align:center;
        p {
            margin:0;
        }
    }

    .upload-hidden {
        display:none!important;
    }

    .wpcf7-not-valid-tip {
        color:$danger;
    }

    .wpcf7-response-output {
        padding:10px;
        width:100%;
        margin:0;
        border:none;
        text-align:center;
        &.wpcf7-validation-errors {
            background:$danger;
            color:#fff;
        }
    }

}

.hidden {
    display:none;
}