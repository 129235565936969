/* Styling for the career type pages */
.tax-career_type {

 .career-type-hero {
  height: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
  position: relative;

  .bg-image {
   background-size: cover;
   background-position: center center;
   background-repeat: no-repeat;
   position: absolute;
   z-index: 0;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
   opacity: .3;

   @include media-breakpoint-up(xl) {
    opacity: 1;
   }
  }

  .hero-content {
   height: 100%;
   position: relative;
   z-index: 10;
   @extend .d-flex;
   @extend .align-items-center;
   @extend .justify-content-start;

   .title, .subtitle {
    text-transform: uppercase;
    letter-spacing: 4px;
   }

   .title {
    font-size: 26px;
    @include media-breakpoint-up(lg) {
     font-size: 45px;
    }
   }

   .subtitle {
    font-size: 20px;
    color: $grey-darker;
    margin-top:20px;
    @include media-breakpoint-up(lg) {
     font-size:28px;
    }
   }

   .btn {
    margin-top:40px;
    
    @include media-breakpoint-down(sm) {
      padding-left: 20px!important;
      padding-right: 20px!important;
    }
   }

  }

  @include media-breakpoint-up(md) {
   height: 450px;
  }

 }

 .career-listings {
  padding:50px 15px;
  @include media-breakpoint-up(lg) {
   padding:50px 0;
  }

  .filters {
   @extend .d-flex;
   @extend .justify-content-end;
   width:100%;
   margin-bottom:20px;
   .filter-set {
    .styled-select {
     background-image: url('../images/small-select-arrow.svg');
     background-repeat: no-repeat;
     background-position: 96% center;
     background-size:15px 15px;
     height: 29px;
     overflow: hidden;
     width: 170px;
     select {
      background: transparent;
      border: none;
      text-transform:uppercase;
      color:$brand;
      font-size: 16px;
      height: 29px;
      padding: 5px; /* If you add too much padding here, the options won't show in IE */
      width: 200px;
      outline:none;
      -webkit-appearance: none;
      appearance: none;
     }
    }

   }
  }

  .section-title, .title-supporting {
   text-align:center;
   width:100%;
   display:block;
   line-height:1.3em;
  }

  .section-title {
   color:$black;
   font-size:25px;
   line-height:1.3em;
   text-transform: uppercase;
   margin-bottom:50px;
   letter-spacing: 4px;

   @include media-breakpoint-up(md) {
    font-size:45px;
   }
   span {
    color:$brand;
   }

   &.small-title {
    font-size:20px;
    @include media-breakpoint-up(md) {
     font-size:35px;
    }
   }

   &.with-supporting {
    margin-bottom:20px;
   }
  }

  .title-supporting {
   font-size:14px;
   @include media-breakpoint-up(md) {
    font-size:16px;
   }
  }

  .career-listing-item {
   border:1px solid lighten($grey, 20%);
   margin:10px 0;
   text-transform: uppercase;
   @extend .d-flex;
   @extend .align-items-center;
   @extend .flex-wrap;

   > div {
    @extend .d-flex;
    @extend .align-items-center;
    @extend .flex-wrap;
    padding:25px;
    @include media-breakpoint-up(lg) {
     height:100%;
    }
    > .col-12 {
     padding:0;
    }
   }

   .title, p, a {
    font-size:16px;
    padding:0;
    margin:0;
   }

   p, a {
    color:$black;
   }

   .title {
    color:$brand;

    @include media-breakpoint-up(md) {
      padding-right: 20px;
    }

    a {
     font-size:1em;
     color:inherit;
    }
   }

   .title-type {
    @extend .col-12;
    @extend .col-md-8;
   }
   .locations {
    border-top:1px solid lighten($grey, 20%);
    @include media-breakpoint-up(md) {
     border-top:none;
     border-left:1px solid lighten($grey, 20%);
    }
    @extend .col-12;
    @extend .col-md-4;
    @extend .ml-auto;
    .location {
     &:after {
      content:', ';
      padding-right:10px;
      padding-left:2px;
     }

     &:last-child {
      &:after {
       content:'';
       padding-right:0;
       padding-left:0;
      }
     }
    }
   }

  }

 }

 .notify-form {
  padding:50px 0;
  text-align:center;

  form {
   background:#fff;
   padding:20px 0;
   border:1px solid $form-border;

   @include media-breakpoint-up(md) {
    padding: 50px 10px;
   }

   @include media-breakpoint-up(lg) {
    padding:50px;
   }

   .subtitle {
    color:$black;
    text-transform:uppercase;
    font-size:1rem;
    font-weight:$font-semibold;
    margin:20px 0 0;
    @include media-breakpoint-up(md) {
     margin:25px 0 15px;
    }
   }

   .form-group {
    label, input {
     width:100%;
     text-align:left;
    }
    label {
     margin-bottom:20px;
     font-size: 17px;

     @include media-breakpoint-up(lg) {
      font-size:18px;
     }
    }
    input[type="text"], input[type="email"] {
     border:none;
     border-bottom:1px solid $form-border;
     padding:0;
     padding-bottom:15px;
    }
   }

   .notify-checkbox {
    margin:0;
    padding:10px 0;
    @include media-breakpoint-up(lg) {
     padding:10px;
    }
    input {
     display:none;
    }

    label {
     background:$black;
     color:#fff;
     margin:0;
     text-transform: uppercase;
     letter-spacing: 2px;
     @extend .d-flex;
     @extend .align-items-center;
     padding: 25px 20px;

     @include media-breakpoint-up(lg) {
      padding:25px;
     }

     &:after {
      content:'';
      width:20px;
      height:20px;
      @extend .ml-auto;
      display:block;
      background:#fff;
      @extend .d-flex;
      @extend .align-items-center;
      @extend .justify-content-center;
      padding:0;
      border:1px solid $form-border;
     }
    }

    input:checked + label {
     background:$brand;
     &:after {
      content: '✓';
      color:$brand;
      font-size:16px;
     }
    }

   }

   .notify-selectors {
    margin:20px 0;

    h3 {
     text-transform:uppercase;
     color:$black;
     font-size:16px;
     letter-spacing: 2px;
     margin-bottom:20px;
    }

    &.notify-locations {
     padding:30px 0;
     border:1px solid $form-border;
     border-radius:5px;
    }
   }

   .custom-checkbox {
    margin:20px 0;
    input[type="checkbox"] {
     position:absolute;
     top:30px;
     right: 0;
     width:20px;
     height:20px;
     border:1px solid $form-border;
     background:#fff;
     @extend .d-flex;
     @extend .align-items-center;
     @extend .justify-content-center;
     padding:0;
     & + label {

      position:relative;
      padding:0 40px;
      display:block;

      a {
       color:$brand;
       text-decoration: underline;
      }
     }
    }
   }

   button {
    margin-top:20px;
   }
  }
 }

 .sent-alert {
  padding:20px;
  text-align:center;
  border:2px solid green;
  margin-top:20px;
  p {
   margin:0;
  }
 }

 .hidden {
  display: none;
 }

 .wpcf7-not-valid-tip {
  color: $danger;
 }

 .wpcf7-response-output {
  padding: 10px;
  width: 100%;
  margin: 0;
  border: none;
  text-align: center;
  &.wpcf7-validation-errors {
   background: $danger;
   color: #fff;
  }
 }

}