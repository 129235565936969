.textbanner-with-title {
	text-align: center;

	.textbanner-textarea {
		position: relative;
		padding-top: 60px;
		padding-bottom: 20px;
		
		&:after { 
			content:'';
			position: absolute;
			top:0;
			left:50%;
		    height: 2px;
		    width: 65px;
		    margin-left:-32.5px;
			background-color:$brand;
		}

		&__hdr {
			letter-spacing: 5px;
			color:$grey-darker;
			font-size:25px;
			padding-bottom: 30px;

			@include media-breakpoint-up(sm) {
				font-size:32px;
			}
		}

		&__para {
    		max-width: 800px;
    		margin-left: auto;
    		margin-right: auto;
			font-size: 16px;
			padding-bottom: 35px;
			margin-bottom: 0;

			@include media-breakpoint-up(lg) {
				font-size: 18px;
			}

			@include media-breakpoint-up(xl) {
				font-size: 22px;
			}

    	}
	}

}
