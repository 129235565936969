/* ==========================================================================
   
   ADVISORS

========================================================================== */

.block-advisors {

	position: relative;
	background: $white-alt;
	padding: 40px 20px;

	width: 100%;
	margin-bottom: 60px;

	display: none;

	@include media-breakpoint-up(lg) {

		padding: 120px 0 40px 80px;
		margin-bottom: 0;
		display: block;

	}

	&:after {

		@include media-breakpoint-up(lg) {

			content: '';
			background: $white-alt;
			position: absolute;
			top: 0;
			right: -100vw;

			width: 100vw;
			height: 100%;
			
		}

	}

	.advisor-list {

		display: flex;
		flex-direction: column;
		flex-wrap: wrap;

		@include media-breakpoint-up(lg) {

			flex-direction: row;
			min-height: 550px;
		}

		li {

			font-size: 14px;
		    font-weight: bold;
		    letter-spacing: 0.1em;
			text-transform: uppercase;
			color: $grey;
			transition: padding 0.3s ease-in-out;

			&:not(:last-child) {

				margin-bottom: 15px;

			}

			&:hover {

				padding-left: 5px;
				color: $brand;

				a {

					color: $brand;

				}

			}

			&.title {

				@include media-breakpoint-up(lg) {

					margin-bottom: 51px;
					
				}

			}

			&.current,
			&.current a {

				color: $brand;

			}

			a {

				color: $grey;

			}

		}

		.list-col {

			@include media-breakpoint-up(lg) {

				width: calc( 100% / 3);
				
			}

			&:not(:last-child) {

				@include media-breakpoint-up(lg) {

					padding-right: 40px;

				}

			}

		}

	}

	.list-primary {

		@extend .list-unstyled;

		li {

			font-size: 14px;
		    font-weight: bold;
		    letter-spacing: 0.1em;
			text-transform: uppercase;
			color: $grey;

			&.current {

				color: $brand;

			}

			&.show-children > ul {

				opacity: 1;
				visibility: visible;

			}

			> ul {

				opacity: 0;
				visibility: hidden;

				@extend .list-unstyled;

			}

		}

	}

	.list-collection {

		@include media-breakpoint-up(xl) {

			display: block;

		}

	}

	.list-secondary {

		@extend .list-unstyled;

		&:not(:first-child) {

			display: none;

		}

		li {

			&.title {

				@extend .d-none;
				@xtend .d-xl-block;

			}


			&:not(.title) {

				list-style-type: disc;
				margin-left: 20px;

			}

		}

	}

}

.advisor-selected {
	
	min-height: 400px;
	width: 100%;
	display: block;
	background: $white;
	border: 1px solid rgba($grey, 0.3);

	text-align: center;
	padding: 10px 20px 40px 20px;
	margin-top: 30px;

	max-width: 280px;
	margin-left: auto;
	margin-right: auto;

	@include media-breakpoint-up(sm) {

		max-width: 340px;

	}

	@include media-breakpoint-up(lg) {

		margin-top: 0;
		max-width: 100%;
		margin-left: 0;
		margin-right: 0;
		
	}

	.logo {

		margin-top: 30px;
	    margin-bottom: 30px;
		max-width: 120px;
		display: block;
		margin-left: auto;
		margin-right: auto;

	}

	.profile {

		width: 110px;
		height: 110px;
		border-radius: 100%;
		margin-bottom: 30px;
		display: inline-block;
		background-size: cover;
	    background-position: center;
	    background-repeat: no-repeat;

	    &.animate-out {

	    	opacity: 0;
	    	transform: scale(0.8);

	    }

	    &.animate-in {

	    	opacity: 1;
	    	transform: scale(1);

	    }

	}

	.title,
	.position,
	.copy,
	.profile {

		transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;

	}

	.title,
	.position {

		font-size: 15px;
		font-weight: $font-bold;
		line-height: 18px;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		margin-bottom: 10px;

		&.animate-out {

			opacity: 0;
			transform: translateX(0);

		}

		&.animate-in {

			opacity: 1;
			transform: translateX(0);

		}
		
	}

	.title {

		color: $brand;

	}

	.position {

		color: $grey;

	}

	.copy {

		font-size: 14px;
		line-height: 38px;
		min-height: 76px;
		margin-bottom: 0;

		&.animate-out {

			opacity: 0;
			transform: translateY(20px);

		}

		&.animate-in {

			opacity: 1;
			transform: translateY(0);

		}

	}

	hr {

		margin: 60px -20px 50px -20px;
		position: relative;

		&:before {

			content: '\e90c';
			@include icons;
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			transform: translateY(-50%);
			color: $brand;
			font-size: 45px;

			margin-left: auto;
			margin-right: auto;


		}

	}

}

.advisor-map {

	max-width: 280px;
	width: 100%;

}

.page-template-cdv-about-advisors .hero {

	margin-top: 50px;
	margin-bottom: 40px;

	@include media-breakpoint-up(lg) {

		margin-top: 0;
		margin-bottom: 0;

	}
	
}

.block-advisors-mobile {

	position: fixed;
	top: 51px;
	left: 0;
	right: 0;
	background: #F4F6FB;
	z-index: 500;

	@include media-breakpoint-up(lg) {

		display: none;

	}

	.advisor-lists {

		display: none;


	}

	.advisor-dropdown {

		position: relative;
		padding: 15px 20px;

		background: $brand;
		color: $white;
		font-size: 14px;
		font-weight: 600;
		text-transform: uppercase;
		text-align: center;
		letter-spacing: 0.2em;
		display: block;

		@include media-breakpoint-up(lg) {

			display: none!important;

		}

		&.active {

			.icon:before {

				transform: rotate(90deg);

			}
		}

		.icon {

		    font-size: 12px;
    		margin-left: 10px;

			&:before {

				color: $white;
				transition: all 0.3s ease-in-out;

			}

		}

	}

	.advisor-info {

		display: none;

	}

	.advisor-locations {

		.advisor-location {
			
			color: $brand;
			font-size: 14px;
			letter-spacing: 0.2em;
			width: 100%;
			text-transform: uppercase;
			font-weight: bold;
			position: relative;
			display: block;
			padding: 20px 50px;

			.icon {

				font-size: 10px;
				position: absolute;
			    top: 50%;
			    right: 50px;
			    transform: translateY(-50%);

			}

		}

		.advisors {

			padding: 20px 30px 30px 30px;
			background: $white;
			margin-bottom: 0;

			li {

				color: $brand;
				font-size: 14px;
				letter-spacing: 0.2em;
				text-transform: uppercase;
				font-weight: bold;
				line-height: 15px;
			    margin-left: 40px;

				&:not(:last-child) {

					margin-bottom: 15px;

				}

			}

		}

	}

	.advisor-selected {

		display: none;
		margin-bottom: 30px;

	}

}